"use client";

import clsx from "clsx";
import React, { useLayoutEffect, useMemo } from "react";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "block.text"
 */
export const TEXT_BLOCK_COMPONENT_NAME = "block.text";

function generateHash(input: string): string {
  let hash = 0,
    i,
    chr;
  if (input.length === 0) {
    return hash.toString();
  }

  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return `script-${hash}`;
}

export const TextBlock = ({ ...component }) => {
  const componentText = component?.text;

  // useMemo um nur beim Ändern von componentText erneut zu extrahieren (verhindert redundante useEffect-Calls)
  const extractedScripts = useMemo(() => {
    const scriptSrcRegex = /<script src="(.+?)"><\/script>/gi;
    const scriptInlineRegex = /<script>([\s\S]*?)<\/script>/gi;
    const srcMatches = [];
    const inlineScripts = [];
    let modifiedText = componentText;
    let match;

    // Scripte mit Src-Attribut extrahieren
    while ((match = scriptSrcRegex.exec(componentText)) !== null) {
      srcMatches.push(match[1]);
      modifiedText = modifiedText.replace(match[0], ""); // Original entfernen
    }

    // Inline-Skripte extrahieren
    while ((match = scriptInlineRegex.exec(componentText)) !== null) {
      inlineScripts.push(match[1]);
      modifiedText = modifiedText.replace(match[0], ""); // Original entfernen
    }

    return { scripts: srcMatches, inlineScripts: inlineScripts, modifiedComponentText: modifiedText };
  }, [componentText]);

  useLayoutEffect(() => {
    const scriptLoadPromises: Promise<void>[] = [];

    extractedScripts.scripts.forEach((scriptSrc: string) => {
      const scriptId = generateHash(scriptSrc);
      const existingScript = document.getElementById(scriptId);
      if (!existingScript) {
        const scriptLoadPromise = new Promise<void>((resolve, reject) => {
          const newScript = document.createElement("script");
          newScript.src = scriptSrc;
          newScript.async = true;
          newScript.id = scriptId;
          newScript.onload = () => resolve();
          newScript.onerror = () => reject(new Error(`Failed to load script: ${scriptSrc}`));

          const el = document.querySelector(`.text-block-${component.id}`);
          if (el) {
            el.appendChild(newScript);
          }
        });
        scriptLoadPromises.push(scriptLoadPromise);
      }
    });

    // Inline-Skripte ausführen, wenn alle externen JavaScripts geladen sind
    Promise.all(scriptLoadPromises).then(() => {
      extractedScripts.inlineScripts.forEach((inlineScript) => {
        try {
          // eslint-disable-next-line no-new-func
          new Function(inlineScript)();
        } catch (error) {
          console.error("Error executing inline script:", error);
        }
      });
    });
  }, [component.id, extractedScripts.scripts, extractedScripts.inlineScripts]);

  return (
    <div className={clsx("container m-auto pb-5 max-w-[1620px] mx-auto", `text-block-${component.id}`, { "p-5": component.padding !== false })}>
      <div dangerouslySetInnerHTML={{ __html: extractedScripts.modifiedComponentText }} />
    </div>
  );
};
