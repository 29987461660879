"use client";

import React, { useEffect, useState } from "react";

import { InformationLayout } from "@/components/pages/information/Components/InformationLayout";
import { InformationNavigation } from "@/components/pages/information/Components/Navigation";
import { InformationNavigationItemInterface } from "@/interfaces/pages/Information/InformationPageInterface";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "sidebar.bar"
 */
export const TABLE_OF_CONTENT_COMPONENT_NAME = "sidebar.bar";

export const TableOfContent = ({ ...component }) => {
  const [selectedNavigation, setSelectedNavigation] = useState<InformationNavigationItemInterface | null>(null);

  useEffect(() => {
    const { items } = component;
    items.length >= 1 && !selectedNavigation && setSelectedNavigation(items.at(0));
  }, [component, selectedNavigation]);

  return (
    <div className="max-w-[1620px] mx-auto">
      <div className="container m-auto mt-10 p-5">
        <div className="grid grid-cols-7">
          <div className="col-span-7 lg:col-span-2 ml-0 lg:ml-24 hidden lg:block lg:w-7/12 mt-10">
            <h1 className="text-xl mb-5 font-bold uppercase" style={{ color: component?.textColor }}>
              {component?.title}
            </h1>
            <InformationNavigation
              navigation={component?.items ?? []}
              textColor={component?.textColor}
              setSelectedNavigation={setSelectedNavigation}
              selectedNavigation={selectedNavigation}
            />
          </div>
          <div className="col-span-7 lg:col-span-5">
            <InformationLayout navigation={component?.items ?? []} setSelectedNavigation={setSelectedNavigation} selectedNavigation={selectedNavigation}>
              {component?.items.map((item: InformationNavigationItemInterface) => {
                return (
                  <div
                    key={item.name}
                    id={item.name}
                    style={{ color: item?.textColor ?? "#000" }}
                    className="break-words mb-5"
                    dangerouslySetInnerHTML={{ __html: item?.text ?? "" }}
                  />
                );
              })}
            </InformationLayout>
          </div>
        </div>
      </div>
    </div>
  );
};
