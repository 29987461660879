"use client";

import clsx from "clsx";
import Link from "next/link";
import React from "react";

import { CommonInterface, ImagePreviewInterface, useImageSrcSet } from "@/hook/image/ImageSrcSetHook";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface TwoImageBannerInterface extends StrapiComponentInterface {
  backgroundColor?: string;
  banner?: ImageInterface;
  bannerMobile?: ImageInterface;
  bannerTablet?: ImageInterface;
  bannerTitle?: string;
  button?: string;
  buttonLink?: string;
  image?: ImageInterface;
  imageMobile?: ImageInterface;
  imageTablet?: ImageInterface;
  imageTitle?: string;
  padding?: boolean | null;
  subTitle?: string;
  text?: string;
  textColor?: string;
  title?: string;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "banner.image-banner"
 */
export const TWO_IMAGE_BANNER_COMPONENT_NAME = "banner.image-banner";

export const TwoImageBanner = ({ ...component }: TwoImageBannerInterface) => {
  const { banner, bannerTitle, image, imageTitle } = component;

  const { getAlternativeText, getCaption } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  const bannerCaption = component.bannerTitle ?? getCaption(banner);
  const bannerAlternativeText = bannerTitle ?? getAlternativeText(banner);
  const imageCaption = imageTitle ?? getCaption(image);
  const imageAlternativeText = imageTitle ?? getAlternativeText(image);

  const {
    common: bannerCommon,
    desktop: bannerDesktop,
    mobile: bannerMobile,
    tablet: bannerTablet,
  } = PrepareImage({
    alt: bannerAlternativeText,
    image: banner,
    imageMobile: component.bannerMobile,
    imageTablet: component.bannerTablet,
    title: bannerCaption,
  });

  const { desktop, tablet, mobile, common } = PrepareImage({
    alt: imageAlternativeText,
    image: image,
    imageMobile: component.imageMobile,
    imageTablet: component.imageTablet,
    title: imageCaption,
  });

  return (
    <div className="max-w-[1620px] mx-auto p-0 md:p-10">
      <div className={clsx("mb-16 grid grid-cols-12 md:p-0 lg:gap-4", { "lg:py-10 lg:pb-10": component.padding !== false })}>
        <div className="col-span-12 lg:col-span-6 lg:max-w-[960px] max-w-[1620px] ml-auto">
          <picture>
            <source media="(min-width: 1024px)" srcSet={bannerDesktop} />
            <source media="(min-width: 768px)" srcSet={bannerTablet} />
            <img {...bannerMobile} style={{ objectFit: "cover" }} className={"h-[18rem] md:h-[24rem] lg:h-[40rem] lg:w-[56rem]"} alt={bannerCommon.alt} />
          </picture>
        </div>
        <div className={`col-span-12 lg:col-span-6 ${!component?.backgroundColor && "bg-gray-100"}`} style={{ backgroundColor: component.backgroundColor }}>
          <div className="grid grid-cols-12 gap-4 lg:max-w-[960px] max-w-[1620px] mr-auto">
            <div className="col-span-6 m-auto">
              <picture>
                <source media="(min-width: 1024px)" srcSet={desktop} />
                <source media="(min-width: 768px)" srcSet={tablet} />
                <img
                  {...mobile}
                  style={{ objectFit: "cover" }}
                  className={"md:h-[21.5rem] md:pt-0 md:p-5 lg:h-[35rem] sm:w-[10rem] md:w-[100%] h-[14rem] pb-5 md:pb-0 mt-5 lg:pl-10 lg:pt-12"}
                  alt={common.alt}
                />
              </picture>
            </div>
            <div className="col-span-6 lg:px-5 flex flex-wrap lg:justify-center md:items-center md:pt-0 pt-5">
              <div>
                <div style={{ color: component?.textColor ?? "#000" }}>
                  <h2 className="uppercase text-2xl lg:text-3xl">
                    {component?.title && <span className="font-bold">{component.title}</span>}
                    <br />
                    <span className="break-words">{component?.subTitle}</span>
                  </h2>
                  {component?.text && <p className="mt-3 mb-3 text-base lg:text-xl">{component.text}</p>}
                </div>
                {component?.button && component?.buttonLink && (
                  <Link href={`${component.buttonLink}`} className="font-bold text-base lg:text-xl underline md:mb-0 mb-10">
                    {component.button}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PrepareImageInterface {
  alt: string;
  image?: ImageInterface;
  imageMobile?: ImageInterface;
  imageTablet?: ImageInterface;
  title: string;
}

const PrepareImage = ({ image, imageTablet, imageMobile, alt, title }: PrepareImageInterface) => {
  const { preview, getImageConfiguration, getImage, getCaption, getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  const defaultImage = getImage(image) as string;
  const imageTitle = title ?? getCaption(image);
  const imageAlternativeText = alt ?? getAlternativeText(image);

  const prepareCommon: CommonInterface = {
    alt: imageAlternativeText,
    title: imageTitle,
  };

  const prepareImageConfiguration: ImagePreviewInterface = getImageConfiguration({
    desktop: defaultImage,
    mobile: getImage(imageMobile) ?? defaultImage,
    tablet: getImage(imageTablet) ?? defaultImage,
  });

  return {
    ...preview(prepareCommon, prepareImageConfiguration),
    common: prepareCommon,
  };
};
