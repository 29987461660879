"use client";

import clsx from "clsx";
import { usePathname, useRouter } from "next/navigation";
import React, { useState } from "react";

import { Collapse, SubCategory } from "@/components/Form/Button/Collapse";
import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";
import { CheckLineIcon } from "@/components/Icon/Check/CheckLineIcon";
import { MedusaSorting } from "@/interfaces/Sort/SortInterface";
import { useSearch } from "@/lib/contexts/search-context";

const RenderArrow = ({ selected }: { selected: boolean }) => {
  return selected ? <ArrowDropUpIcon width={20} className="float-right mt-1" /> : <ArrowDropDownIcon width={20} className="float-right mt-1" />;
};

export const SortComponent = () => {
  const [open, setOpen] = useState(false);
  const { availableSorting } = useSearch();
  const pathname = usePathname();
  const router = useRouter();

  const handleSortClick = (sort: MedusaSorting) => {
    // Parse the current query string
    const currentQueryParams = new URLSearchParams(window.location.search);

    // Update the sortField and sort parameters
    currentQueryParams.set("sortField", sort.field);
    currentQueryParams.set("sort", sort.direction);

    // Stringify the updated query parameters
    const updatedQueryString = currentQueryParams.toString();

    // Push the updated query string to the router
    router.push(`${pathname}?${updatedQueryString}`, { scroll: false });
  };
  return (
    <Collapse
      enabled={open}
      onClick={() => setOpen(!open)}
      title={
        <>
          <span className={`tracking-wide font-semibold text-xl uppercase`}>
            <span className="mr-1">Sortierung</span>
          </span>
          <RenderArrow selected={open} />
        </>
      }
      customStyle={"bg-white -ml-3 pr-2 !w-auto"}
      customContentStyle={"bg-white"}
    >
      <div className="m-auto">
        {availableSorting?.map((sort: MedusaSorting) => {
          return (
            <SubCategory
              key={sort.field + sort.direction}
              className={"!pl-7"}
              customStyle={clsx(sort.selected && "font-bold", "!ml-0")}
              subCategory={{ name: sort.title }}
              icon={sort.selected && <CheckLineIcon className="float-right mt-1" width={20} />}
              onClick={() => handleSortClick(sort)}
            />
          );
        })}
      </div>
    </Collapse>
  );
};
