"use client";

import clsx from "clsx";
import { getImageProps } from "next/image";
import Link from "next/link";
import React from "react";

import { PRODUCT_TEXT_ALIGNMENT, ProvidedProductCard } from "@/components/Product/ProductCard";
import { useImageSrcSet } from "@/hook/image/ImageSrcSetHook";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import { StrapiProductInterface } from "@/interfaces/Api/Product/ProductInterface";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface OneProductCampaignInterface extends StrapiComponentInterface {
  angebotsflag?: string;
  angebotsflagColor?: string;
  angebotsflagBgColor?: string;
  backgroundColor?: string;
  button?: string;
  buttonLink?: string;
  image?: ImageInterface;
  imageMobile?: ImageInterface;
  imageTablet?: ImageInterface;
  imageTitle?: string;
  padding?: boolean | null;
  product?: {
    data?: StrapiProductInterface;
  };
  subTitle?: string;
  text?: string;
  textColor?: string;
  title?: string;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "campaign.one-product"
 */
export const ONE_PRODUCT_CAMPAIGN_COMPONENT_NAME = "campaign.one-product";

export const OneProductCampaign = ({ ...component }: OneProductCampaignInterface) => {
  const { getCaption, getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const common = {
    alt: component.imageTitle ?? getAlternativeText(component.image),
    title: component.imageTitle ?? getCaption(component.image),
  };

  if (!component?.image?.data) {
    return <></>;
  }

  const img = {
    desktop: component.image.data?.attributes.url as string,
    mobile: (component.imageMobile?.data?.attributes.url as string) ?? (component.image.data?.attributes.url as string),
    tablet: (component.imageTablet?.data?.attributes.url as string) ?? (component.image.data?.attributes.url as string),
  };

  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    height: 875,
    quality: 80,
    src: (process.env.STRAPI_URL ?? "") + img.desktop,
    width: 1440,
  });

  const {
    props: { srcSet: tablet },
  } = getImageProps({
    ...common,
    height: 1334,
    quality: 70,
    src: (process.env.STRAPI_URL ?? "") + img.tablet,
    width: 750,
  });

  const {
    props: { ...mobile },
  } = getImageProps({
    ...common,
    height: 1334,
    priority: true,
    quality: 70,
    src: (process.env.STRAPI_URL ?? "") + img.mobile,
    width: 750,
  });

  return (
    <div
      className={clsx("", {
        "bg-gray-100": component.backgroundColor ?? false,
        "mt-5": component.padding !== false,
      })}
      style={{ backgroundColor: component.backgroundColor }}
    >
      <div className="grid grid-cols-12 max-w-[1620px] mx-auto p-5 md:p-11">
        <div className="col-span-12 md:col-span-6 lg:col-span-12">
          <div className="col-span-2 md:col-span-1 lg:col-span-2 mb-10">
            <div style={{ color: component.textColor ?? "#000" }}>
              {component.title && <div className="text-2xl lg:text-3xl font-bold uppercase">{component.title}</div>}
              {component.subTitle && <div className="text-2xl lg:text-3xl uppercase">{component.subTitle}</div>}

              {component?.text && <p className="mt-3 mb-3 text-base lg:text-xl">{component.text}</p>}
              {component?.button && component.buttonLink && (
                <Link href={`${component.buttonLink}`} className="font-bold underline text-base lg:text-xl">
                  {component.button}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={"hidden md:block lg:hidden col-span-6 row-span-2 mt-auto"}>
          {component.product?.data && (
            <div className="mt-10 md:mt-0 ml-auto mr-auto">
              <ProvidedProductCard product={component.product.data} className={"max-w-[30rem] md:w-[14rem] mx-auto lg:w-[27.5rem]"} />
            </div>
          )}
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-7">
          {component.angebotsflag && (
            <div
              className={clsx("min-h-[1.5rem] absolute mt-5", {
                "bg-primary": component.angebotsflagBgColor ?? false,
              })}
              style={{
                ...(component.angebotsflagBgColor && { backgroundColor: component.angebotsflagBgColor }),
              }}
            >
              <p
                className={clsx("py-1 px-3 uppercase font-semibold break-words text-xs lg:text-lg whitespace-pre-line first-line:font-bold lg:leading-5", {
                  "text-white": component.angebotsflagColor ?? false,
                })}
                style={{
                  ...(component.angebotsflagColor && { color: component.angebotsflagColor }),
                }}
              >
                {component.angebotsflag ?? null}
              </p>
            </div>
          )}
          <picture>
            <source media="(min-width: 1024px)" srcSet={desktop} />
            <source media="(min-width: 768px)" srcSet={tablet} />
            <img {...mobile} style={{ objectFit: "cover" }} className={"w-full aspect-[4/3]"} alt={common.alt} />
          </picture>
        </div>
        {component.product?.data && (
          <div className="col-span-12 md:hidden lg:block lg:col-span-4 lg:col-start-9 mt-10 md:mt-0 mx-auto w-full md:w-auto">
            <ProvidedProductCard
              alignment={PRODUCT_TEXT_ALIGNMENT.RIGHT}
              product={component.product.data}
              className={"!w-[10rem] mx-auto md:!w-[14rem] lg:!w-[27.5rem]"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
