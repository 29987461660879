"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { PriceFilter } from "@/components/Filter/PriceFilter";
import { TextFilter } from "@/components/Filter/TextFilter";
import { MinMaxInterface } from "@/components/Form/Input/Range/MultiRangeInput";
import { EqualizerLineIcon } from "@/components/Icon/Equalizer/EqualizerLineIcon";
import { Flyout } from "@/components/Modal/Flyout";
import { SortComponent } from "@/components/pages/c/Sort/SortComponent";
import { Facet, FacetBase, useSearch } from "@/lib/contexts/search-context";
import { FILTER_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";
import Button from "@/modules/common/components/button";

interface Props {
  hide: string | string[];
  className?: string;
}

type FilterObject =
  | { attribute: string; type: string; values?: string[]; min?: string; max?: string }
  | { attribute: string; type: string; min: string; max: string };

function setFacetData(index: string, attributeName: string, key2: string, attributeValue: string, facet: FacetBase) {
  // Check if the key starts with "filter", has the same index, and ends with "values"
  if (key2.includes("filter") && key2.includes(`[${index}]`) && key2.includes("[values]")) {
    // Add a new value to the facet with the title from the value and default recordCount and selected
    facet.values.push({
      title: attributeValue,
      recordCount: 0,
      selected: true,
    });
  } else if (key2.includes("min") && attributeName === "price") {
    facet.values.push({
      title: "min " + attributeValue,
      recordCount: 0,
      selected: true,
    });
  } else if (key2.includes("max") && attributeName === "price") {
    facet.values.push({
      title: "max " + attributeValue,
      recordCount: 0,
      selected: true,
    });
  }
}

export function transformUrlParamsToFacets(urlParams: Record<string, string>): FacetBase[] {
  const facets: FacetBase[] = [];

  // Iterate over each key-value pair in the urlParams
  for (const [key, attributeName] of Object.entries(urlParams)) {
    // Extract the index from the key
    const index = key.match(/\d+/)?.[0];

    // Check if the key starts with "filter" and ends with "attribute"
    if (!index || !key.includes("filter") || !key.includes("[attribute]") || key.includes("[values]")) {
      continue;
    }

    // Create a new facet with the name from the value
    const facet: FacetBase = {
      name: attributeName,
      values: [],
    };

    // Iterate over each key-value pair in the urlParams again to find the values for this facet
    for (const [key2, attributeValue] of Object.entries(urlParams)) {
      setFacetData(index, attributeName, key2, attributeValue, facet);
    }

    // Add the facet to the facets array
    facets.push(facet);
  }

  return facets;
}

function priceFilter(
  index: number,
  selectedFacets: FacetBase[] | undefined,
  setSelectedFacets: (value: ((prevState: FacetBase[] | undefined) => FacetBase[] | undefined) | FacetBase[] | undefined) => void,
  setRange: (value: ((prevState: MinMaxInterface) => MinMaxInterface) | MinMaxInterface) => void,
  activated: boolean,
  selectedRange?: MinMaxInterface,
  min?: number,
  max?: number,
) {
  return (
    <PriceFilter
      key={index}
      isEnabled={() => {
        const selectedFilters = selectedFacets ? [...selectedFacets] : [];
        setSelectedFacets(selectedFilters);
      }}
      setSelectedPrice={(selectedValues) => {
        setRange(selectedValues);
      }}
      min={min ?? 0}
      max={max ?? 2000}
      selectedRange={selectedRange}
      activated={activated}
    />
  );
}

export const Filter = ({ className, hide = [] }: Props) => {
  const [activated, setActivated] = useState<boolean>(false);
  const { facets, selectedFacets, setSelectedFacets, priceRange } = useSearch();
  const [range, setRange] = useState<MinMaxInterface>({ min: priceRange?.min ?? 0, max: priceRange?.max ?? 2000 });
  const [selectedRange, setSelectedRange] = useState<MinMaxInterface | undefined>(undefined);
  const {
    setModal,
    modal: { isShow, name },
    close,
  } = useSideModal();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const selectedRangeRef = useRef<MinMaxInterface | undefined>(selectedRange);
  const activatedRef = useRef<boolean>(activated);

  useEffect(() => {
    selectedRangeRef.current = selectedRange;
  }, [selectedRange]);

  useEffect(() => {
    setRange({ min: priceRange?.min ?? 0, max: priceRange?.max ?? 2000 });
  }, [priceRange]);

  useEffect(() => {
    activatedRef.current = activated;
  }, [activated]);

  useEffect(() => {
    const urlParams = Object.fromEntries(searchParams.entries());
    const updatedFacets = transformUrlParamsToFacets(urlParams);

    const updateRangeDisplay = (facet: FacetBase | undefined) => {
      let min = priceRange?.min ?? 0;
      let max = priceRange?.max ?? 2000;

      if (facet) {
        for (const ele of facet.values) {
          if (ele.title.includes("min")) {
            min = parseInt(ele.title.replace("min ", ""));
          }

          if (ele.title.includes("max")) {
            max = parseInt(ele.title.replace("max ", ""));
          }
        }
      }

      setActivated(min > range.min || max < range.max);
      setSelectedRange({ min: min, max: max });
    };

    if (updatedFacets.find((e) => e.name === "price")) {
      updateRangeDisplay(updatedFacets.find((e) => e.name === "price"));
    }
  }, [searchParams]);

  useEffect(() => {
    // DEFAULT FILTER SETZEN NACH URL

    const urlParams = Object.fromEntries(searchParams.entries());
    const updatedFacets = transformUrlParamsToFacets(urlParams);

    setSelectedFacets(updatedFacets);
  }, []);

  const isPriceFiltered = useMemo(() => {
    if (!selectedRange) {
      return false;
    }

    return (selectedRange?.min ?? 0) > (priceRange?.min ?? 0) || (selectedRange?.max ?? 2000) < (priceRange?.max ?? 2000);
  }, [priceRange, selectedRange, activated, range]);

  function createFilterObject(facet: FacetBase, index: number) {
    const type = facet.name === "price" ? "range" : "value";
    if (facet.values.length > 0 && facet.name !== "price") {
      return {
        attribute: `filter[${index}][attribute]=${facet.name}`,
        type: `filter[${index}][type]=${type}`,
        values: facet.values.map((value, valueIndex) => `filter[${index}][values][${valueIndex}]=${encodeURIComponent(value.title)}`),
      };
    }
    return null;
  }

  function isValueFilter(filter: FilterObject): filter is { attribute: string; type: string; values: string[] } {
    return (filter as { values: string[] }).values !== undefined;
  }

  function createQueryString(reducedFilters: FilterObject[], queryRange: MinMaxInterface) {
    if (queryRange.min > (priceRange?.min ?? 0) || queryRange.max < (priceRange?.max ?? 2000)) {
      reducedFilters.push({
        attribute: `filter[${reducedFilters.length}][attribute]=price`,
        type: `filter[${reducedFilters.length}][type]=range`,
        min: `filter[${reducedFilters.length}][min]=${queryRange.min}`,
        max: `filter[${reducedFilters.length}][max]=${queryRange.max}`,
      });
    }

    return reducedFilters
      ?.flatMap((filter, index) => {
        if (isValueFilter(filter)) {
          return [
            filter.attribute.replace(/\d+/, String(index)),
            filter.type.replace(/\d+/, String(index)),
            ...filter.values.map((value) => value.replace(/\d+/, String(index))),
          ];
        }
        if (filter.min || filter.max) {
          return [
            filter.attribute.replace(/\d+/, String(index)),
            filter.type.replace(/\d+/, String(index)),
            filter.min?.replace(/\d+/, String(index)) ?? "",
            filter.max?.replace(/\d+/, String(index)) ?? "",
          ];
        }
        return [];
      })
      .join("&");
  }

  function callFilters() {
    let reducedFilters = selectedFacets?.reduce<{ attribute: string; type: string; values?: string[]; min?: string; max?: string }[]>((acc, facet, index) => {
      const filterObject = createFilterObject(facet, index);
      if (filterObject) {
        acc.push(filterObject);
      }
      return acc;
    }, []);

    reducedFilters = reducedFilters ?? [];

    const queryString = createQueryString(reducedFilters, range);

    router.push(`${pathname}?${queryString}`, { scroll: false });
  }

  const clearFilters = () => {
    setSelectedFacets([]);
    setRange({ min: priceRange?.min ?? 0, max: Math.ceil(priceRange?.max ?? 2000) });
    setSelectedRange({ min: Math.floor(priceRange?.min ?? 0), max: Math.ceil(priceRange?.max ?? 2000) });
    router.push(pathname, { scroll: false });
  };

  let showingPrice = false;
  const hideFacets = Array.isArray(hide) ? hide : [hide];
  if (!hideFacets.includes("product_story")) {
    hideFacets.push("product_story");
  }

  const showSelectedFacets = (value: FacetBase, index: number) => {
    if ("price" !== value.name) {
      return <></>;
    }

    showingPrice = true;
    return priceFilter(index, selectedFacets, setSelectedFacets, setRange, activated, selectedRange, priceRange?.min, priceRange?.max);
  };

  const showFacets = (facetsToShow: Facet, index: number) => {
    if (hideFacets.includes(facetsToShow.name)) {
      return <></>;
    }

    if (facetsToShow.uiSettings.type === "number" && !showingPrice) {
      return priceFilter(index, selectedFacets, setSelectedFacets, setRange, activated, selectedRange, priceRange?.min, priceRange?.max);
    }

    if (facetsToShow.uiSettings.type === "text") {
      return (
        <TextFilter
          key={index}
          attribute={facetsToShow.name}
          title={facetsToShow.uiSettings.name}
          selectedValues={facetsToShow.values.filter((v) => v.selected).map((v) => v.title)}
          availableValues={facetsToShow.values}
        />
      );
    }

    return <></>;
  };

  const numberOfSelectedFacets = useMemo(() => {
    let count = isPriceFiltered ? 1 : 0;
    if (selectedFacets) {
      count += selectedFacets.length;
    }
    return count;
  }, [selectedFacets, isPriceFiltered]);

  return (
    <div className={className ?? "py-5"}>
      <Button
        className="text-xs lg:text-base font-semibold uppercase rounded-full w-auto mb-5 px-3 flex items-center"
        padding={"pr-2"}
        onClick={() => setModal({ name: FILTER_MODAL, isShow: true })}
      >
        <EqualizerLineIcon className="w-3 h-3 lg:w-5 lg:h-5" />
        <span className="pr-5 pl-2">Sortierung & Filter {numberOfSelectedFacets > 0 ? `(${numberOfSelectedFacets})` : null}</span>
      </Button>

      <Flyout
        open={name === FILTER_MODAL && isShow}
        onClose={close}
        button={
          <button
            type="button"
            className="py-2 px-4 bg-pink-600 text-white rounded hover:bg-pink-700 mr-2 uppercase w-full sticky bottom-0"
            onClick={() => {
              callFilters();
              close();
            }}
          >
            Ergebnisse anzeigen
          </button>
        }
      >
        <div>
          <SortComponent />

          <div className="uppercase tracking-wide text-xl font-semibold my-4 ml-2">Filter</div>

          {selectedFacets?.map(showSelectedFacets)}
          {facets?.map(showFacets)}

          {(isPriceFiltered || (selectedFacets && selectedFacets.length > 0)) && (
            <button type="button" className="underline text-sm ml-5 mt-5 font-semibold" onClick={() => clearFilters()}>
              Alle Filter löschen
            </button>
          )}
        </div>
      </Flyout>
    </div>
  );
};
