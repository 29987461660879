"use client";

import Image from "next/image";
import React, { useState } from "react";

import { Collapse } from "@/components/Form/Button/Collapse";
import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";
import { InformationPaymentInterface } from "@/interfaces/pages/Information/InformationPageInterface";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "collapse.payments"
 */
export const COLLAPSE_PAYMENTS_COMPONENT_NAME = "collapse.payments";

const RenderArrow = ({ selected }: { selected: boolean }) => {
  return selected ? <ArrowDropUpIcon width={20} className="float-right mt-1" /> : <ArrowDropDownIcon width={20} className="float-right mt-1" />;
};

export const PaymentCollapses = ({ ...component }) => {
  const [selectedPayments, setSelectedPayments] = useState<InformationPaymentInterface[]>([]);

  return (
    <div className="container m-auto mt-10 p-5 mb-10 max-w-[1620px] mx-auto">
      <div className="w-full lg:w-10/12 m-auto">
        <div className="text-xl mb-5 uppercase" style={{ color: component?.textColor ?? "#000" }}>
          {component?.title}
        </div>

        {component?.payments?.map((payment: InformationPaymentInterface) => {
          return (
            <div key={`payment-${payment.title}`}>
              <hr className="mt-5 mb-2 h-0.5 border-t-0 bg-black opacity-100" />
              <Collapse
                title={
                  <div style={{ color: component?.textColor ?? "#000" }}>
                    <span className={`tracking-wide font-semibold text-md`}>
                      <span className="mr-1 uppercase">{payment.title}</span>
                    </span>
                    <RenderArrow selected={selectedPayments.includes(payment)} />
                  </div>
                }
                customStyle={"bg-white hover:bg-gray-200"}
                customContentStyle={"bg-white"}
                onClick={() => {
                  const payments = [...selectedPayments];

                  if (payments.includes(payment)) {
                    payments.splice(payments.indexOf(payment), 1);
                    setSelectedPayments(payments);
                    return;
                  }

                  payments.push(payment);
                  setSelectedPayments(payments);
                }}
                enabled={selectedPayments.includes(payment)}
                id={"paymentsCollapse"}
              >
                <div className="m-auto">
                  {Array.isArray(payment.image) &&
                    payment.image.data.map((image, index) => {
                      const floating = payment.image.data.length - 1 > index;

                      return (
                        <Image
                          width={1250}
                          height={0}
                          key={`provider-collapse-image-${payment.title}`}
                          alt={image.attributes.name ?? payment.imageTitle ?? "provider image"}
                          title={payment.imageTitle}
                          className={`w-16 h-16 ${floating ? "float-left" : ""} mr-2`}
                          loader={(loader) => process.env.STRAPI_URL + loader?.src}
                          src={image.attributes.url as string}
                        />
                      );
                    })}

                  <div dangerouslySetInnerHTML={{ __html: payment.text as string }} className="mt-2" style={{ color: payment?.textColor ?? "#000" }} />
                </div>
              </Collapse>
            </div>
          );
        })}
      </div>
    </div>
  );
};
