import "@/styles/slider.css";

import React, { useCallback, useEffect, useState } from "react";

export interface MinMaxInterface {
  min: number;
  max: number;
}

interface MultiRangeInputInterface {
  min: number;
  max: number;
  onMouseUp: (range: MinMaxInterface) => void;
  defaultSelected: MinMaxInterface;
}

export const MultiRangeInput = ({ min, max, onMouseUp, defaultSelected }: MultiRangeInputInterface) => {
  const [rangeValue, setRangeValue] = useState<MinMaxInterface>(defaultSelected);
  const getPercent = useCallback((value: number) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  useEffect(() => {
    setRangeValue(defaultSelected);
  }, [defaultSelected]);

  return (
    <>
      <div className="grid grid-cols-3 text-center pt-[10px]">
        <div className="col-span-1">
          <span className="font-semibold">{rangeValue.min} &euro;</span>
        </div>
        <div className="col-span-1">
          <span className="font-semibold">bis</span>
        </div>
        <div className="col-span-1">
          <span className="font-semibold">{rangeValue.max} &euro;</span>
        </div>
      </div>

      <div className="container relative bottom-8 h-[45px]">
        <input
          type="range"
          min={min}
          max={max}
          value={rangeValue.min}
          onMouseUp={() => onMouseUp(rangeValue)}
          onChange={(event) => {
            const range = { ...rangeValue };
            range.min = Math.min(+event.target.value, rangeValue.max - 1);
            setRangeValue(range);
          }}
          className={`thumb thumb--zindex-3 top-[1.9rem] ${rangeValue.min > max - 100 && "thumb--zindex-5"}`}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={rangeValue.max}
          onMouseUp={() => onMouseUp(rangeValue)}
          onChange={(event) => {
            const range = { ...rangeValue };
            range.max = Math.max(+event.target.value, rangeValue.min + 1);
            setRangeValue(range);
          }}
          className="thumb thumb--zindex-4 top-1.5"
        />

        <div className="slider">
          <div className="slider__track" />
          <div
            className="slider__range"
            style={{ width: `${getPercent(+rangeValue.max) - getPercent(rangeValue.min)}%`, left: `${getPercent(rangeValue.min)}%` }}
          />
        </div>
      </div>
    </>
  );
};
