"use client";

import clsx from "clsx";
import Link from "next/link";
import React from "react";

import { BrandScrollContainer } from "@/components/pages/app/Slider/BrandScrollContainer";
import { BrandSlider, SlideInterfaceItem } from "@/components/Slider/BrandSlider";
import { StrapiImage } from "@/data/strapi";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";

export interface BrandSliderBlockInterface extends StrapiComponentInterface {
  title?: string;
  subTitle?: string;
  text?: string;
  button?: string;
  buttonLink?: string;
  backgroundColor?: string;
  textColor?: string;
  slides?: SlideInterface[] | SlideInterfaceItem[];
  products?: {
    data: unknown[];
  };
  padding?: boolean | null;
}

export interface SlideInterface {
  displayName: string;
  link: string;
  image: StrapiImage;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 *
 * BrandSlider:
 * __component: "slider.image-slider"
 *
 * SubBrandSlider:
 * __component: "brand.sub-brand-slider"
 */
export const BRAND_SLIDER_COMPONENT_NAME = "brand.brand-slider";
export const SUB_BRAND_SLIDER_COMPONENT_NAME = "brand.sub-brand-slider";

const normalizeSubBrand = (subBrand: SlideInterface) => ({
  ...subBrand,
  subBrandSliderImage: subBrand?.image,
});

export const BrandSliderBlock = ({ ...component }: BrandSliderBlockInterface & { subBrand?: boolean }) => {
  return (
    <div style={{ backgroundColor: `${component?.backgroundColor}` }}>
      <div className={clsx("p-0 max-w-[1620px] mx-auto", { "md:p-10 mt-10": component.padding !== false })}>
        <div className={clsx("", { "p-5 md:p-0": component.padding !== false })}>
          <div style={{ color: component?.textColor ?? "#000" }}>
            {component?.title && <div className="uppercase text-2xl md:text-xl lg:text-3xl font-bold">{component.title}</div>}
            {component?.subTitle && <div className="uppercase text-2xl md:text-xl lg:text-3xl">{component.subTitle}</div>}

            {component?.text && <p className="mt-5 mb-5 text-base lg:text-xl">{component.text}</p>}
          </div>

          {component?.button && component?.buttonLink && (
            <Link href={`${component.buttonLink}`} className="font-bold underline text-base lg:text-xl">
              {component.button}
            </Link>
          )}
        </div>

        <div className="pt-3">
          {component.__component === BRAND_SLIDER_COMPONENT_NAME ? (
            <div className="mt-0 md:mt-5 p-0">
              <BrandSlider slides={(component?.slides as SlideInterfaceItem[] | null) ?? []} />
            </div>
          ) : (
            <div style={{ backgroundColor: `${component?.backgroundColor}` }}>
              <BrandScrollContainer id="subBrandSlider" subbrands={(component?.slides as SlideInterface[])?.map(normalizeSubBrand)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
