import { Properties } from "csstype";
import React, { useEffect, useRef, useState } from "react";

import { handleCarouselIndex, SlideButton } from "@/components/Carousel/index";

export const SlideCarouselItem = ({ children, className = "", style = {} }: { children: React.ReactNode; className?: string; style?: Properties }) => {
  return (
    <div className={`relative duration-1000 flex-[0_0_100%] carousel-item ${className}`} style={style}>
      {children}
    </div>
  );
};

export const SlideCarousel = ({
  totalChildren,
  children,
  className,
  slideInterval = 5000,
  buttonClassName = "",
  buttonClasses = "",
}: {
  totalChildren: number;
  children: React.ReactNode;
  slideInterval?: number;
  className?: string;
  buttonClassName?: string;
  buttonClasses?: string;
}) => {
  const [slide, setSlide] = useState<number>(0);
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((index: number) => handleCarouselIndex(true, index, totalChildren));
    }, slideInterval);

    return () => clearInterval(interval);
  }, [slide, totalChildren, slideInterval]);

  useEffect(() => {
    if (container.current) {
      const currentElement = container.current.childNodes.item(slide) as HTMLElement;
      const previousElement = container.current.childNodes.item((slide - 1 + totalChildren) % totalChildren) as HTMLElement;

      if (previousElement) {
        previousElement.classList.add("opacity-0");
        previousElement.classList.remove("opacity-100");
      }
      if (currentElement) {
        currentElement.classList.remove("opacity-0");
        currentElement.classList.add("opacity-100");
      }
    }
  }, [slide, totalChildren]);

  const onClick = (next: boolean, currentIndex?: number) => {
    setSlide(handleCarouselIndex(next, currentIndex ?? slide, totalChildren));
  };

  const renderInputs = (labels: boolean) => {
    const inputs = [];

    for (let index = 0; index < totalChildren; index++) {
      if (labels) {
        inputs.push(
          <label
            key={index}
            htmlFor={`slideLabel${index}`}
            style={{
              backgroundColor: `${index === slide ? "#d20073" : "transparent"}`,
            }}
            className="manual-btn"
          ></label>,
        );
      } else {
        inputs.push(
          <input
            key={index}
            type="radio"
            name="radio-btn"
            className="hidden"
            id={`slideLabel${index}`}
            onClick={() => {
              setSlide(index);
            }}
          />,
        );
      }
    }

    return inputs;
  };

  if (!children || (Array.isArray(children) && children.length === 0)) {
    return null;
  }

  return (
    <div className={className}>
      <div className="relative w-full mb-[.75rem] top-[-2px] overflow-hidden">
        <div className="w-full overflow-hidden flex">
          {renderInputs(false)}

          <div className="w-full flex" style={{ marginLeft: `-${slide * 100}%` }} ref={container}>
            {children}
          </div>
        </div>

        <div className={buttonClassName}>
          <SlideButton onClick={() => onClick(false)} isNext={false} buttonClasses={buttonClasses} />
          <SlideButton onClick={() => onClick(true)} isNext={true} buttonClasses={buttonClasses} />
        </div>
      </div>

      <div className="manual-carousel-slides">{renderInputs(true)}</div>
    </div>
  );
};
