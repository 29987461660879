"use client";

import { useInfiniteQuery } from "@tanstack/react-query";
import { forEach } from "lodash";
import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation";
import hash from "object-hash";
import React, { Suspense, useCallback } from "react";

import { page } from "@/actions/campaigns";
import { DefaultFilter, SearchParams } from "@/actions/search";
import { FilterOverwritesInterface } from "@/components/Brand/Articles";
import { ProductList } from "@/components/List/Product/ProductList";
import { StrapiDynamicComponent } from "@/data/strapi";
import { useSharedStates } from "@/hook/content/productListState";
import { addFilterAndSortToSearchParams, getCategoryPathByHandle } from "@/lib/data";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "product.listing-anhand-sku"
 */
export const FF_CAMPAIGN_PAGE_COMPONENT_NAME = "product.factfinder-campaign-page";

interface FactFinderCampaignPageProductsProps extends StrapiDynamicComponent {
  pageId?: string;
}

const FactFinderCampaignPageProductsList = (props: FactFinderCampaignPageProductsProps) => {
  const {
    previews,
    setPreviews,
    currentProductsCount,
    setCurrentProductsCount,
    totalProductsCount,
    setTotalProductsCount,
    percentSeen,
    setPercentSeen,
    processing,
    setProcessing,
    hideFilters,
    setHideFilters,
    cart,
    setFacets,
    setAvailableSorting,
    setPriceRange,
    searchParams,
    gtmEventSent,
    setGtmEventSent,
    gtmOmittedProductsCount,
    setGtmOmittedProductsCount,
    pagination,
  } = useSharedStates();

  const query = useCallback(
    async (filters: FilterOverwritesInterface, searchPs: ReadonlyURLSearchParams | null, offset?: number, limit?: number) => {
      const filter: DefaultFilter[] = [];
      const hiddenFilters: string[] = [];
      const requestLimit = limit ?? 40;

      if (typeof cart?.sales_channel_id !== "string") {
        return;
      }

      if (filters.brands.length) {
        filter.push({
          attribute: "brand",
          type: "value",
          values: filters.brands,
        });
      }

      if (filters.stories.length) {
        filter.push({
          attribute: "product_story",
          type: "value",
          values: filters.stories,
        });
      }

      if (filters.skus.length) {
        filter.push({
          attribute: "parent_sku",
          type: "value",
          values: filters.skus,
        });
      }

      filters.raw?.forEach((rawFilter) => {
        const existingFilter = filter.find((f) => f.attribute === rawFilter.filter.data.attributes.factfinder_attribute);
        if (existingFilter) {
          if (!existingFilter.values.includes(rawFilter.value)) {
            existingFilter.values.push(rawFilter.value);
          }
          return;
        }

        filter.push({
          attribute: rawFilter.filter.data.attributes.factfinder_attribute,
          type: "value",
          values: [rawFilter.value],
        });
      });

      for (const category of filters.categories) {
        const categoryPath = await getCategoryPathByHandle(category);
        let preDefinedFilter = "";
        for (let i = 0; i < categoryPath.length; i++) {
          const currentAttribute = i === 0 ? "categoryROOT" : `categoryROOT${preDefinedFilter}`;
          preDefinedFilter += `/${categoryPath[i]}`;
          filter.push({
            attribute: currentAttribute,
            type: "value",
            values: [categoryPath[i]],
          });
        }
      }

      // add each filter to the hideFilter as we don't want the user to modify those
      forEach(filter, (attribute) => {
        hiddenFilters.push(attribute.attribute);
      });

      setHideFilters(hiddenFilters);

      const params: SearchParams = {
        type: "search",
        additionalOptions: {
          expand: "variants,variants.options",
          pageId: props.pageId,
        },
      };

      if (offset) {
        params.offset = offset * requestLimit - requestLimit;
      }

      addFilterAndSortToSearchParams(params, searchPs, filter);

      return page({ pageId: props.pageId ?? "", sales_channel_id: cart.sales_channel_id, limit: requestLimit, ...params });
    },
    [setHideFilters, cart?.sales_channel_id],
  );

  const {
    data: infiniteData,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    fetchPreviousPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery(
    [`getDefinedProductList${props.id}`, searchParams?.toString() ? searchParams.toString() + hash(props.pageId ?? "") : hash(props.pageId ?? ""), cart?.id],
    ({ pageParam }) => {
      setProcessing(true);
      return query(
        {
          brands: [],
          raw: [],
          stories: [],
          skus: [],
          categories: [],
        },
        searchParams,
        pageParam,
      );
    },
    {
      getNextPageParam: (response) => {
        return response?.body.nextPage ?? response?.body.pagination?.nextLink?.number;
      },
    },
  );

  const handleNextPage = async () => {
    if (isFetching) {
      return false;
    }

    pagination.goToPage(pagination.page + 1);
    await fetchNextPage();
  };

  const handlePreviousPage = async () => {
    if (isFetching) {
      return false;
    }

    pagination.goToPage(pagination.page - 1);
    await fetchPreviousPage();
  };

  const listProps = {
    infiniteData,
    hasNextPage,
    fetchNextPage: handleNextPage,
    hasPreviousPage,
    fetchPreviousPage: handlePreviousPage,
    isLoading,
    isFetching,
    hideFilters,
    currentProductsCount,
    setCurrentProductsCount,
    processing,
    setProcessing,
    previews,
    setPreviews,
    percentSeen,
    setPercentSeen,
    cart,
    totalProductsCount,
    setTotalProductsCount,
    setFacets,
    setAvailableSorting,
    setPriceRange,
    listId: `factFinderCampaign_${props.pageId}`,
    listName: `factFinderCampaign_${props.pageId}`,
    gtmEventSent,
    setGtmEventSent,
    gtmOmittedProductsCount,
    setGtmOmittedProductsCount,
  };

  return <ProductList {...listProps} />;
};

const SuspendedFactFinderCampaignPageProductsList = (props: FactFinderCampaignPageProductsProps) => (
  <Suspense fallback={<div>Lade Artikel...</div>}>
    <FactFinderCampaignPageProductsList {...props} />
  </Suspense>
);

export default SuspendedFactFinderCampaignPageProductsList;
