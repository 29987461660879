"use client";

import React, { useEffect, useState } from "react";

import Button from "@/components/Form/Button/Button";
import { ArrowUpIcon } from "@/components/Icon/Arrow/ArrowUpIcon";

export const ScrollTopButton = () => {
  const [isShow, setShow] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", () => (window.scrollY > 1000 ? setShow(true) : setShow(false)));
    return () => window.removeEventListener("scroll", () => setShow(false));
  }, []);

  if (!isShow) {
    return <></>;
  }

  return (
    <Button
      className="p-5 font-semibold text-lg uppercase bg-primary w-10 h-10 m-auto text-center"
      rounded
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <ArrowUpIcon width="20" height="20" className="mt-2 text-center relative right-1.5 bottom-0.5" fill="#fff" />
    </Button>
  );
};
