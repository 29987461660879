"use client";

import clsx from "clsx";
import React from "react";

import { SlideCarousel, SlideCarouselItem } from "@/components/Carousel/SlideCarousel";
import Button from "@/components/Form/Button/Button";
import { isElementActiveByDate } from "@/data/strapi";
import { CommonInterface, ImagePreviewInterface, useImageSrcSet } from "@/hook/image/ImageSrcSetHook";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface SlideInterfaceItem {
  image: ImageInterface;
  imageTablet?: ImageInterface;
  imageMobile?: ImageInterface;
  headline: string;
  secondHeadline: string;
  text: string;
  button?: string;
  buttonLink?: string;
  textColor?: string;
  imageTitle: string;
  backgroundColor?: string;
  startingAt?: string;
  endingAt?: string;
}

export interface ImageSliderInterface extends StrapiComponentInterface {
  ImageSlider?: SlideInterfaceItem[];
  textColor?: string;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "slider.image-slider"
 */
export const IMAGE_SLIDER_COMPONENT_NAME = "slider.image-slider";

const TextSlide = (props: { slide: SlideInterfaceItem; index: number; className: string; defaultTextColor?: string }) => {
  return (
    <div className={`col-span-12 md:col-span-6 p-8 ${props.className}`}>
      <div className="relative md:left-10 min-h-[15rem] md:h-[21rem] lg:h-[30rem]">
        <div className="absolute bottom-0 w-full">
          <div style={{ color: props.slide?.textColor ?? props.defaultTextColor ?? "#000" }}>
            <div className="text-2xl lg:text-3xl font-bold uppercase">{props.slide.headline}</div>
            <div className="text-2xl lg:text-3xl font-light uppercase">{props.slide.secondHeadline}</div>

            <div className="mt-3">
              <p className="md:w-3/4 lg:max-w-[42.5rem] text-base lg:text-xl mb-7">{props.slide.text}</p>
            </div>
          </div>

          {props.slide.button && props.slide.buttonLink && (
            <Button
              title={props.slide.button}
              href={props.slide.buttonLink}
              outline
              className="uppercase text-center mt-6 w-full md:w-3/4 lg:w-[24rem] relative left-0 lg:right-10 text-base lg:text-2xl font-light"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const ImageSlider = ({ ...component }: ImageSliderInterface) => {
  const { getAlternativeText, getCaption, preview, getImageConfiguration, getImage } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const slides = component.ImageSlider ?? [];
  const activeSlides = slides.filter((properties) => isElementActiveByDate(properties));

  if (!activeSlides) {
    return null;
  }

  return (
    <SlideCarousel
      totalChildren={activeSlides?.length ?? 0}
      buttonClassName={"max-w-[1620px] w-full mx-auto absolute h-[688px] md:h-[400px] lg:h-[592px] top-0 -translate-x-1/2 left-1/2 pointer-events-none"}
      buttonClasses={"top-[-22%] md:top-auto"}
    >
      {activeSlides?.map((slide: SlideInterfaceItem & { index?: number }, index: number) => {
        const { image, imageTablet, imageMobile, imageTitle } = slide;
        const defaultImage = getImage(image) as string;
        const imageCaption = imageTitle ?? getCaption(image);
        const imageAlternativeText = imageTitle ?? getAlternativeText(image);

        const prepareCommon: CommonInterface = {
          title: imageCaption,
          alt: imageAlternativeText,
        };

        const prepareImageConfiguration: ImagePreviewInterface = getImageConfiguration({
          desktop: defaultImage,
          tablet: getImage(imageTablet) ?? defaultImage,
          mobile: getImage(imageMobile) ?? defaultImage,
        });

        const { desktop, tablet, mobile } = preview(prepareCommon, prepareImageConfiguration);

        const style = slide.backgroundColor ? { backgroundColor: slide.backgroundColor } : {};
        const bgColorClass = slide.backgroundColor ? "" : "bg-gray-100";

        return (
          <SlideCarouselItem
            key={index}
            className={clsx(`${bgColorClass} ease-in-out duration-1000`, {
              "opacity-100": index === 0,
              "opacity-0": index !== 0,
            })}
            style={style}
          >
            <div className="max-w-[1620px] mx-auto">
              <div className={`grid w-full grid-cols-12`}>
                <TextSlide slide={slide} index={index} className="hidden md:block" />
                <div className="col-span-12 md:col-span-6">
                  <picture>
                    <source media="(min-width: 1024px)" srcSet={desktop} />
                    <source media="(min-width: 768px)" srcSet={tablet} />
                    <img {...mobile} style={{ objectFit: "cover" }} className={"w-full h-[24rem] md:h-[25rem] lg:h-[37rem]"} alt={prepareCommon.alt} />
                  </picture>
                </div>
                <TextSlide slide={slide} index={index} className="block md:hidden" defaultTextColor={component.textColor} />
              </div>
            </div>
          </SlideCarouselItem>
        );
      })}
    </SlideCarousel>
  );
};
