import { ProductCategory } from "@medusajs/medusa";
import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation";

import { DefaultFilter, search as executeSearch, SearchParams } from "@/actions/search";
import medusaRequest from "@/data/medusa-fetch";
import { addFilterAndSortToSearchParams } from "@/lib/data";

/**
 * Fetches a category by handle, using the Medusa API or the Medusa Category Module, depending on the feature flag.
 * @param handle (string) - The handle of the category to retrieve
 * @returns (array) - An array of categories (should only be one)
 */
export async function getCategoryByHandle(handle: string) {
  return medusaRequest("GET", "/product-categories", {
    query: {
      handle,
    },
  })
    .then((res) => res.body)
    .catch((err) => {
      throw err;
    });
}

export interface getCategoriesInterface {
  include_descendants_tree?: boolean;
  parent_category_id?: string;
  limit?: number;
  fields?: string;
}

/**
 * Fetches a category by handle, using the Medusa API or the Medusa Category Module, depending on the feature flag.
 * @returns (array) - An array of categories
 */
export async function getCategories(
  includeDescendantsTree = true,
  parent_category_id?: string,
): Promise<{ limit?: number; offset?: number; count?: number; product_categories: ProductCategory[] }> {
  const query: getCategoriesInterface = {
    include_descendants_tree: includeDescendantsTree,
    limit: 1500,
    fields: "id,name,handle,rank,parent_category_id",
  };

  if (parent_category_id) {
    query.parent_category_id = parent_category_id;
  }

  return await medusaRequest("GET", "/product-categories", { query: query as Record<string, unknown> })
    .then((res) => res.body)
    .catch((err) => {
      throw err;
    });
}

export const popularFilterData = () => {
  return [
    {
      name: "Preis",
    },
    {
      name: "Größe",
    },
  ];
};

export function isString(field: unknown): field is string {
  return typeof field === "string";
}

export function isSortDirection(direction: string): direction is "asc" | "desc" {
  return direction === "asc" || direction === "desc";
}

interface QueryParams {
  displayName: string | string[];
  searchParams: ReadonlyURLSearchParams;
  predefinedFilter?: string;
  filterValues?: string[];
  offset?: number;
  limit?: number;
}

export const query = async ({ displayName, searchParams, offset, limit, filterValues, predefinedFilter = "brand" }: QueryParams) => {
  const filter = [
    {
      attribute: predefinedFilter,
      type: "value",
      values: filterValues ?? [displayName],
    },
  ] as unknown as DefaultFilter[];

  const params: SearchParams = {
    type: "search",
    additionalOptions: {
      expand: "variants,variants.options",
    },
    offset,
    limit,
  };

  addFilterAndSortToSearchParams(params, searchParams, filter);
  return await executeSearch(params);
};
