import React from "react";

import Button from "@/components/Form/Button/Button";

interface Props {
  onClick: () => void;
  text?: string;
  testId?: string;
}

export default function PageNavigationButton({ onClick, text = "weitere Artikel laden", testId }: Props) {
  return <Button title={text} outline onClick={onClick} className={"p-5 font-bold uppercase text-center"} testId={testId} />;
}
