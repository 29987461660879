import clsx from "clsx";
import React from "react";

interface CheckboxInputInterface extends Omit<React.HTMLProps<HTMLInputElement>, "label" | "id"> {
  id: string;
  label: React.ReactNode;
  errorMessage?: string;
  inline?: boolean;
}

export const CheckboxInput: React.ForwardRefExoticComponent<React.PropsWithoutRef<CheckboxInputInterface> & React.RefAttributes<HTMLInputElement>> =
  React.forwardRef(({ id, label, errorMessage, className, inline, ...props }: CheckboxInputInterface, ref) => {
    return (
      <>
        <div className={clsx({ "flex items-center": inline !== true }, { "p-5": inline === true }, className)}>
          <input
            id={id}
            type="checkbox"
            className={clsx("w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded accent-pink-500 focus:ring-pink-500 focus:ring-2", {
              "inline-block": inline === true,
            })}
            value="1"
            ref={ref}
            {...props}
          />
          <label htmlFor={id} className="ms-2">
            {label}
          </label>
        </div>
        {errorMessage ? <p className="text-red-500 font-semibold">{errorMessage}</p> : ""}
      </>
    );
  });

CheckboxInput.displayName = "CheckboxInput";
