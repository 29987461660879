"use client";

import { useInfiniteQuery } from "@tanstack/react-query";
import { forEach } from "lodash";
import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation";
import hash from "object-hash";
import React from "react";

import { DefaultFilter, search as executeSearch, SearchParams } from "@/actions/search";
import { FilterOverwritesInterface } from "@/components/Brand/Articles";
import { ProductList } from "@/components/List/Product/ProductList";
import { StrapiDynamicComponent } from "@/data/strapi";
import { useSharedStates } from "@/hook/content/productListState";
import { addFilterAndSortToSearchParams, getCategoryPathByHandle } from "@/lib/data";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "product.listing-anhand-sku"
 */
export const DEFINED_PRODUCT_LIST_COMPONENT_NAME = "product.listing-anhand-sku";

interface DefinedProductListProps extends StrapiDynamicComponent {
  products?: {
    id: number;
    data: {
      attributes: {
        metadata?: Record<string, unknown>;
      };
    }[];
  };
}

export const DefinedProductList = (props: DefinedProductListProps) => {
  const {
    previews,
    setPreviews,
    currentProductsCount,
    setCurrentProductsCount,
    totalProductsCount,
    setTotalProductsCount,
    percentSeen,
    setPercentSeen,
    processing,
    setProcessing,
    hideFilters,
    setHideFilters,
    cart,
    setFacets,
    setAvailableSorting,
    setPriceRange,
    searchParams,
    gtmEventSent,
    setGtmEventSent,
    gtmOmittedProductsCount,
    setGtmOmittedProductsCount,
  } = useSharedStates();

  const productSkus = props.products?.data.map((product) => product.attributes?.metadata?.sku).filter((sku): sku is string => !!sku) ?? [];

  const {
    data: infiniteData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    [
      `getDefinedProductList${props.id}`,
      searchParams?.toString() ? searchParams.toString() + hash(JSON.stringify(productSkus)) : hash(JSON.stringify(productSkus)),
      cart?.id,
    ],
    ({ pageParam }) => {
      setProcessing(true);
      return query(
        {
          brands: [],
          raw: [],
          stories: [],
          skus: productSkus,
          categories: [],
        },
        searchParams,
        pageParam,
      );
    },
    {
      getNextPageParam: (response) => response.body.nextPage,
      enabled: (productSkus.length ?? 0) > 0,
    },
  );

  const query = async (filters: FilterOverwritesInterface, searchPs: ReadonlyURLSearchParams | null, offset?: number) => {
    const filter: DefaultFilter[] = [];
    const hiddenFilters: string[] = [];

    if (filters.brands.length) {
      filter.push({
        attribute: "brand",
        type: "value",
        values: filters.brands,
      });
    }

    if (filters.stories.length) {
      filter.push({
        attribute: "product_story",
        type: "value",
        values: filters.stories,
      });
    }

    if (filters.skus.length) {
      filter.push({
        attribute: "parent_sku",
        type: "value",
        values: filters.skus,
      });
    }

    filters.raw?.forEach((rawFilter) => {
      const existingFilter = filter.find((f) => f.attribute === rawFilter.filter.data.attributes.factfinder_attribute);
      if (existingFilter) {
        if (!existingFilter.values.includes(rawFilter.value)) {
          existingFilter.values.push(rawFilter.value);
        }
        return;
      }

      filter.push({
        attribute: rawFilter.filter.data.attributes.factfinder_attribute,
        type: "value",
        values: [rawFilter.value],
      });
    });

    for (const category of filters.categories) {
      const categoryPath = await getCategoryPathByHandle(category);
      let preDefinedFilter = "";
      for (let i = 0; i < categoryPath.length; i++) {
        const currentAttribute = i === 0 ? "categoryROOT" : `categoryROOT${preDefinedFilter}`;
        preDefinedFilter += `/${categoryPath[i]}`;
        filter.push({
          attribute: currentAttribute,
          type: "value",
          values: [categoryPath[i]],
        });
      }
    }

    // add each filter to the hideFilter as we don't want the user to modify those
    forEach(filter, (attribute) => {
      hiddenFilters.push(attribute.attribute);
    });

    setHideFilters(hiddenFilters);

    const params: SearchParams = {
      type: "search",
      additionalOptions: {
        expand: "variants,variants.options",
      },
    };

    if (offset) {
      params.offset = offset;
    }

    addFilterAndSortToSearchParams(params, searchPs, filter);

    return executeSearch(params);
  };

  const listProps = {
    infiniteData,
    hasNextPage,
    fetchNextPage,
    isLoading,
    hideFilters,
    currentProductsCount,
    setCurrentProductsCount,
    processing,
    setProcessing,
    previews,
    setPreviews,
    percentSeen,
    setPercentSeen,
    cart,
    totalProductsCount,
    setTotalProductsCount,
    setFacets,
    setAvailableSorting,
    setPriceRange,
    products: props.products,
    listId: `definedList_${props.id}`,
    listName: `definedList_${props.id}`,
    gtmEventSent,
    setGtmEventSent,
    gtmOmittedProductsCount,
    setGtmOmittedProductsCount,
  };

  return <ProductList {...listProps} />;
};
