"use client";

import { useInfiniteQuery } from "@tanstack/react-query";
import { forEach } from "lodash";
import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation";
import hash from "object-hash";
import React from "react";

import { DefaultFilter, search as executeSearch, SearchParams } from "@/actions/search";
import { FilterOverwritesInterface } from "@/components/Brand/Articles";
import { ProductList } from "@/components/List/Product/ProductList";
import { StrapiDynamicComponent } from "@/data/strapi";
import { useSharedStates } from "@/hook/content/productListState";
import { addFilterAndSortToSearchParams, getCategoryPathByHandle } from "@/lib/data";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "product.listing-mit-filter"
 */
export const FILTERED_PRODUCT_LIST_COMPONENT_NAME = "product.listing-mit-filter";

interface Props extends StrapiDynamicComponent {
  filter?: {
    id: number;
    value: string;
    filter: {
      data: {
        id: number;
        attributes: {
          displayname: string;
          factfinder_attribute: string;
        };
      };
    };
  }[];
}

export const FilteredProductList = (props: Props) => {
  const {
    previews,
    setPreviews,
    currentProductsCount,
    setCurrentProductsCount,
    totalProductsCount,
    setTotalProductsCount,
    percentSeen,
    setPercentSeen,
    processing,
    setProcessing,
    hideFilters,
    setHideFilters,
    cart,
    setFacets,
    setAvailableSorting,
    setPriceRange,
    searchParams,
    gtmEventSent,
    setGtmEventSent,
    gtmOmittedProductsCount,
    setGtmOmittedProductsCount,
  } = useSharedStates();
  async function getCategoryRootPath(category: string, filter: DefaultFilter[]) {
    const categoryPath = await getCategoryPathByHandle(category);
    let preDefinedFilter = "";
    for (let i = 0; i < categoryPath.length; i++) {
      const currentAttribute = i === 0 ? "categoryROOT" : `categoryROOT${preDefinedFilter}`;
      preDefinedFilter += `/${categoryPath[i]}`;
      filter.push({
        attribute: currentAttribute,
        type: "value",
        values: [categoryPath[i]],
      });
    }
  }

  async function processBrandFilters(filters: FilterOverwritesInterface, filter: DefaultFilter[]) {
    if (filters.brands.length) {
      filter.push({
        attribute: "brand",
        type: "value",
        values: filters.brands,
      });
    }
  }

  async function processStoryFilters(filters: FilterOverwritesInterface, filter: DefaultFilter[]) {
    if (filters.stories.length) {
      filter.push({
        attribute: "product_story",
        type: "value",
        values: filters.stories,
      });
    }
  }

  async function processRawFilters(filters: FilterOverwritesInterface, filter: DefaultFilter[]) {
    if (!filters.raw) {
      return;
    }

    for (const rawFilter of filters.raw) {
      const existingFilter = filter.find((f: DefaultFilter) => f.attribute === rawFilter.filter.data.attributes.factfinder_attribute);
      if (existingFilter) {
        if (!existingFilter.values.includes(rawFilter.value)) {
          existingFilter.values.push(rawFilter.value);
        }
        return;
      }

      if (rawFilter.filter.data.attributes.factfinder_attribute === "category") {
        await getCategoryRootPath(rawFilter.value, filter);
      } else {
        filter.push({
          attribute: rawFilter.filter.data.attributes.factfinder_attribute,
          type: "value",
          values: [rawFilter.value],
        });
      }
    }
  }

  async function query(filters: FilterOverwritesInterface, searchParameters: ReadonlyURLSearchParams | null, offset?: number) {
    const filter: DefaultFilter[] = [];
    const hiddenFilters: string[] = [];

    await processBrandFilters(filters, filter);
    await processStoryFilters(filters, filter);
    await processRawFilters(filters, filter);

    for (const category of filters.categories) {
      await getCategoryRootPath(category, filter);
    }

    // add each filter to the hideFilter as we don't want the user to modify those
    forEach(filter, (attribute) => {
      hiddenFilters.push(attribute.attribute);
    });

    setHideFilters(hiddenFilters);

    const params: SearchParams = {
      type: "search",
      additionalOptions: {
        expand: "variants,variants.options",
      },
    };

    if (offset) {
      params.offset = offset;
    }

    addFilterAndSortToSearchParams(params, searchParameters, filter);

    return await executeSearch(params);
  }

  const {
    data: infiniteData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    [
      `getFilteredProductList${props.id}`,
      searchParams?.toString() ? searchParams.toString() + hash(JSON.stringify(props.filter)) : hash(JSON.stringify(props.filter)),
      cart?.id,
    ],
    ({ pageParam }) => {
      setProcessing(true);
      return query(
        {
          raw: props.filter,
          brands: [],
          skus: [],
          stories: [],
          categories: [],
        },
        searchParams,
        pageParam,
      );
    },
    {
      getNextPageParam: (response) => response?.body.nextPage,
      enabled: (props.filter?.length ?? 0) > 0,
    },
  );

  const listProps = {
    infiniteData,
    hasNextPage,
    fetchNextPage,
    isLoading,
    query,
    hideFilters,
    setHideFilters,
    currentProductsCount,
    setCurrentProductsCount,
    processing,
    setProcessing,
    previews,
    setPreviews,
    percentSeen,
    setPercentSeen,
    cart,
    totalProductsCount,
    setTotalProductsCount,
    setFacets,
    setAvailableSorting,
    setPriceRange,
    listId: `filteredList_${props.id}`,
    listName: `filteredList_${props.id}`,
    gtmEventSent,
    setGtmEventSent,
    gtmOmittedProductsCount,
    setGtmOmittedProductsCount,
  };

  return <ProductList {...listProps} />;
};
