"use client";

import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { CheckboxInput } from "@/components/Form/Input/CheckboxInput";
import { Input } from "@/components/Form/Input/Input";
import { FormValues, NewsletterResult, registerNewsletter } from "@/lib/actions/newsletter/register";
import { useAccount } from "@/lib/contexts/account-context";
import Button from "@/modules/common/components/button";

interface SimplifiedNewsletterProps {
  showSettings?: boolean;
  settingsText?: string;
  button?: string;
  defaultEmail?: string;
}

export const NewsletterForm = ({ showSettings, settingsText, button, defaultEmail }: SimplifiedNewsletterProps) => {
  const { customer } = useAccount();
  showSettings ??= Boolean(customer);
  const [email, setEmail] = useState(defaultEmail);

  useEffect(() => {
    if (!defaultEmail && customer?.email) {
      setEmail(customer.email);
    }
  }, [customer, defaultEmail]);

  const {
    register,
    handleSubmit,
    formState: { errors, isLoading },
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      email: email,
      privacy: false,
    },
  });
  const [result, setResult] = useState<NewsletterResult | null>(null);

  const submit = async (data: FormValues) => {
    try {
      setResult(await registerNewsletter(data));
    } catch (error) {
      setError("email", { type: "custom", message: (error as Error).message ?? (error as string).toString() });
    }
  };

  if (result && result.success) {
    return <p>Bitte überprüfen Sie Ihr E-Mail-Postfach und bestätigen Sie Ihre Anmeldung.</p>;
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        label="E-Mail"
        type="text"
        className="w-full mb-3"
        {...register("email", {
          required: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
        })}
        errorMessage={errors?.email?.message}
      />

      <CheckboxInput
        id="newsletter-privacy"
        label={
          <>
            Ja, ich habe die{" "}
            <Link href="/datenschutz" target="_blank" className="underline">
              Datenschutzbestimmungen
            </Link>{" "}
            gelesen und stimme diesen ausdrücklich zu.
          </>
        }
        className="w-full m-3 lg:w-5/6 lg:m-auto text-base font-bold"
        {...register("privacy", {
          required: "Bitte akzeptieren Sie die Datenschutzbestimmungen.",
        })}
        errorMessage={errors?.privacy?.message}
      />

      {showSettings && (
        <div className="w-full my-3">
          <Link href={"/account/newsletter"} className="underline font-bold my-3">
            {settingsText ?? "Deine Newslettereinstellungen"}
          </Link>
        </div>
      )}

      <Button type="submit" className="w-full font-light text-base text-center uppercase mt-10" variant="dark" disabled={isLoading}>
        {button ?? "Newsletter abonnieren"}
      </Button>
    </form>
  );
};
