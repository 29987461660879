"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";

import { BrandScrollContainer } from "@/components/pages/app/Slider/BrandScrollContainer";
import { BrandInterface } from "@/hook/content";
import { useImageSrcSet } from "@/hook/image/ImageSrcSetHook";

interface Props {
  headline?: string;
  subtitle?: string;
  description?: string;
  buttonText?: string | null;
  buttonLink?: string | null;
  subbrands: BrandInterface[];
  id?: string;
  isBrandPage?: boolean;
}

export const SubBrand = ({ className, subbrand, isBrandPage }: { className: string; subbrand: BrandInterface; isBrandPage?: boolean }) => {
  const { getCaption, getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  if (!subbrand) {
    return <></>;
  }

  const { image, imageTitle } = subbrand;
  const title = imageTitle ?? getCaption(image);
  const imageAlternativeText = imageTitle ?? getAlternativeText(image);
  const linkToUse = isBrandPage ? `/b${subbrand.link}` : `${subbrand.link}`;

  return (
    <div className={`rounded ${className}`}>
      <div className="w-full text-center">
        <Link href={`${linkToUse}`}>
          <Image
            loader={(loader) => process.env.STRAPI_URL + loader?.src}
            src={subbrand.subBrandSliderImage?.data?.attributes?.url as string}
            width={1500}
            height={2000}
            title={title}
            alt={imageAlternativeText}
          />
        </Link>
      </div>
      <Link href={`${linkToUse}`}>
        <div className="px-1 py-4">
          <div className="md:text-base lg:text-xl underline font-bold">{subbrand.displayname}</div>
        </div>
      </Link>
    </div>
  );
};
export const SubBrandSlider = ({ id, subbrands, headline, subtitle, description, buttonText, buttonLink, isBrandPage }: Props) => {
  if (subbrands?.length < 3) {
    return <></>;
  }

  if (!id) {
    id = "subbrands";
  }

  return (
    <div className="mt-5 p-5 bg-ch21-gray">
      <div className="p-0 mb-10">
        <div className="md:pl-3 md:pr-3">
          {headline && <div className="text-2xl lg:text-3xl uppercase font-bold">{headline}</div>}
          {subtitle && <div className="text-2xl lg:text-3xl uppercase font-light">{subtitle}</div>}
          {description && <p className="mt-2 mb-5 text-base">{description}</p>}

          {buttonText && buttonLink && (
            <div className="mt-5 mb-5">
              <Link href={`${buttonLink}`} className="font-bold underline text-base lg:text-xl">
                {buttonText}
              </Link>
            </div>
          )}
        </div>
      </div>

      <BrandScrollContainer subbrands={subbrands} id={id} isBrandPage={isBrandPage} />
    </div>
  );
};
