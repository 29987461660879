import React, { useEffect, useState } from "react";

import { Collapse } from "@/components/Form/Button/Collapse";
import { MinMaxInterface, MultiRangeInput } from "@/components/Form/Input/Range/MultiRangeInput";
import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";

export const PRICE_FILTER_NAME = "PRICE_FILTER";

interface PriceFilterInterface {
  setSelectedPrice: (range: MinMaxInterface) => void;
  isEnabled: (enabled: boolean) => void;
  min?: number;
  max?: number;
  selectedRange?: MinMaxInterface;
  activated?: boolean;
}
const RenderArrow = ({ isSelected }: { isSelected: boolean }) => {
  return isSelected ? <ArrowDropUpIcon width={20} className="float-right mt-1" /> : <ArrowDropDownIcon width={20} className="float-right mt-1" />;
};

export const PriceFilter = ({ setSelectedPrice, isEnabled, min, max, selectedRange, activated }: PriceFilterInterface) => {
  const [isSelected, setSelected] = useState<boolean>(false);
  const [isActivated, setActive] = useState<boolean>(activated ?? false);
  const [initialRange, setInitialRange] = useState<MinMaxInterface>({
    min: Math.floor(min ?? 0),
    max: Math.ceil(max ?? 2000),
  });
  const [rangeValue, setRangeValue] = useState<MinMaxInterface>({
    min: selectedRange?.min ?? initialRange.min,
    max: selectedRange?.max ?? initialRange.max,
  });

  useEffect(() => {
    setRangeValue({
      min: selectedRange?.min ?? initialRange.min,
      max: selectedRange?.max ?? initialRange.max,
    });
  }, [selectedRange, initialRange]);

  useEffect(() => {
    if (selectedRange === initialRange) {
      setRangeValue(initialRange);
    }
    setInitialRange({
      min: Math.floor(min ?? 0),
      max: Math.ceil(max ?? 2000),
    });
  }, [min, max]);

  useEffect(() => {
    if (!selectedRange || (selectedRange?.min === initialRange.min && selectedRange?.max === initialRange.max)) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [selectedRange]);

  return (
    <Collapse
      title={
        <>
          <span className={`tracking-wide font-semibold text-md`}>
            <span className="mr-1">Preis</span>
            {isActivated ? "(1)" : <></>}
          </span>
          <RenderArrow isSelected={isSelected} />
        </>
      }
      customStyle={"category-bg-gray -mx-4 mb-1 !w-auto pr-6 pl-11"}
      customContentStyle={"bg-white"}
      onClick={() => setSelected(!isSelected)}
      enabled={isSelected}
      id={"priceFilter"}
    >
      <div className="m-auto px-5">
        <MultiRangeInput
          min={initialRange.min}
          max={initialRange.max}
          defaultSelected={rangeValue}
          onMouseUp={(range) => {
            const { min: minimum, max: maximum } = range;
            const roundedMinimum = Math.floor(minimum);
            const roundedMaximum = Math.ceil(maximum);
            const active = roundedMinimum > initialRange.min || roundedMaximum < initialRange.max;

            setSelectedPrice(range);
            isEnabled(active);

            setRangeValue(range);
            setActive(active);
          }}
        />
      </div>
    </Collapse>
  );
};
