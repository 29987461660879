import Link from "next/link";
import React from "react";

import { InformationNavigationItemInterface } from "@/interfaces/pages/Information/InformationPageInterface";

export const InformationNavigation = ({
  navigation,
  setSelectedNavigation,
  selectedNavigation,
  textColor,
}: {
  navigation: InformationNavigationItemInterface[];
  selectedNavigation: InformationNavigationItemInterface | null;
  setSelectedNavigation: React.Dispatch<React.SetStateAction<InformationNavigationItemInterface | null>>;
  href?: string;
  textColor?: string;
}) => {
  return (
    <>
      {navigation.map((navigation: InformationNavigationItemInterface) => {
        const isSelected: boolean = selectedNavigation === navigation;

        return (
          <Link
            href={navigation.name}
            key={`navigation-${navigation.name}`}
            onClick={() => (navigation === selectedNavigation ? setSelectedNavigation(null) : setSelectedNavigation(navigation))}
          >
            <div
              className={`${isSelected ? "bg-gray-100" : "bg-white"} block mb-2 p-2 hover:bg-gray-100 cursor-pointer font-semibold uppercase text-sm`}
              style={{ color: textColor ?? "#000" }}
            >
              {navigation.title}
            </div>
          </Link>
        );
      })}
    </>
  );
};
