import "@/styles/slider.css";

import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";

interface CarouselInterface {
  children: ReactNode;
  totalChildren: number;
  setSliderIndex: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
  noArrows?: boolean;
}

export const CarouselItem = ({ children, noFade }: { children: ReactNode; noFade?: boolean }) => (
  <div className={clsx({ "carousel-item": !noFade })}>{children}</div>
);

export const handleCarouselIndex = (next: boolean, currentIndex: number, totalChildren: number) => {
  currentIndex += next ? 1 : -1;

  if (currentIndex <= totalChildren - 1 && currentIndex >= 0) {
    return currentIndex;
  }

  return next ? 0 : totalChildren - 1;
};

interface RenderButtonsInterface {
  totalChildren: number;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

const RenderButtons = ({ totalChildren, setIndex, index }: RenderButtonsInterface) => {
  const items = [];

  for (let i = 0; i < totalChildren; i++) {
    items.push(
      <button
        type="button"
        className={`w-3 h-3 m-1 rounded-full ${i === index && "bg-primary"} border border-black`}
        key={`carouselButton${totalChildren}${i}`}
        aria-current="true"
        aria-label="Slide"
        onClick={() => setIndex(i)}
      ></button>,
    );
  }

  return <>{items}</>;
};

export const SlideButton = ({ onClick, isNext, buttonClasses }: { onClick: () => void; isNext: boolean; buttonClasses?: string }) => (
  <button
    onClick={onClick}
    type="button"
    className={`absolute top-0 ${!isNext ? "start-0" : "end-0"} z-10 flex items-center justify-center h-full px-2 cursor-pointer pointer-events-auto group focus:outline-none ${buttonClasses ?? ""}`}
    aria-label={isNext ? "Weiter" : "Zurück"}
  >
    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 border border-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
      {isNext ? (
        <svg
          className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
        </svg>
      ) : (
        <svg
          className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
        </svg>
      )}
    </span>
  </button>
);

export const Carousel = ({ children, totalChildren, setSliderIndex, className, noArrows }: CarouselInterface) => {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((indx: number) => handleCarouselIndex(true, indx, totalChildren));
    }, 5000);

    setSliderIndex(index);
    return () => clearInterval(interval);
  }, [index, setSliderIndex, totalChildren]);

  const onClick = (next: boolean, currentIndex?: number) => {
    setIndex(handleCarouselIndex(next, currentIndex ?? index, totalChildren));
  };

  return (
    <div id="default-carousel" className="relative w-full overflow-y-hidden" data-carousel="slide">
      <div className={`relative ${!className ? "h-56 md:h-[30rem]" : className} overflow-hidden`}>
        <div className="carousel">{children}</div>
      </div>

      <div className="relative z-10 flex bottom-0 -translate-x-1/2 left-1/2 space-x-3 rtl:space-x-reverse">
        <div className="m-auto">
          <RenderButtons totalChildren={totalChildren} index={index} setIndex={setIndex} />
        </div>
      </div>

      {!noArrows && (
        <>
          <SlideButton onClick={() => onClick(false)} isNext={false} />
          <SlideButton onClick={() => onClick(true)} isNext={true} />
        </>
      )}
    </div>
  );
};
