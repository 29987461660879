import React from "react";

import { NavigationBadges } from "@/components/pages/information/Components/NavigationBadges";
import { InformationNavigationItemInterface } from "@/interfaces/pages/Information/InformationPageInterface";

export const InformationLayout = ({
  navigation,
  setSelectedNavigation,
  selectedNavigation,
  children,
}: {
  navigation: InformationNavigationItemInterface[];
  selectedNavigation: InformationNavigationItemInterface | null;
  setSelectedNavigation: React.Dispatch<React.SetStateAction<InformationNavigationItemInterface | null>>;
  children?: React.ReactNode;
  uppercase?: boolean;
}) => {
  return (
    <div>
      <div className="block lg:hidden">
        <NavigationBadges navigation={navigation} setSelectedNavigation={setSelectedNavigation} selectedNavigation={selectedNavigation} uppercase />
      </div>
      {children}
    </div>
  );
};
