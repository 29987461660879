"use client";

import clsx from "clsx";
import { getImageProps } from "next/image";
import React from "react";

import { useImageSrcSet } from "@/hook/image/ImageSrcSetHook";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "block.image"
 */
export const IMAGE_BLOCK_COMPONENT_NAME = "block.image";

export const ImageBlock = ({ ...component }) => {
  const img = {
    desktop: component.image?.data?.attributes.url as string,
    mobile: (component.imageMobile?.data?.attributes.url as string) ?? (component?.image?.data?.attributes.url as string),
    tablet: (component.imageTablet?.data?.attributes.url as string) ?? (component?.image?.data?.attributes.url as string),
  };

  const { getCaption, getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const common = {
    alt: component.imageTitle ?? getAlternativeText(component.image),
    title: component.imageTitle ?? getCaption(component.image),
  };

  if (!component?.image?.data) {
    return <></>;
  }

  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    height: 875,
    quality: 80,
    src: (process.env.STRAPI_URL ?? "") + img.desktop,
    width: 1440,
  });

  const {
    props: { srcSet: tablet },
  } = getImageProps({
    ...common,
    height: 1334,
    quality: 70,
    src: (process.env.STRAPI_URL ?? "") + img.tablet,
    width: 750,
  });

  const {
    props: { ...mobile },
  } = getImageProps({
    ...common,
    height: 1334,
    quality: 70,
    src: (process.env.STRAPI_URL ?? "") + img.mobile,
    width: 750,
  });

  return (
    <picture>
      <source media="(min-width: 1024px)" srcSet={desktop} />
      <source media="(min-width: 768px)" srcSet={tablet} />
      <img
        {...mobile}
        style={{ width: "100%", objectFit: "cover" }}
        className={clsx("pb-5 max-w-[1620px] mx-auto", { "pt-5 px-5": component.padding !== false })}
        alt={common.alt}
      />
    </picture>
  );
};
