import { Cart } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { InfiniteData } from "@tanstack/react-query";
import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation";
import { useSearchParams } from "next/navigation";
import React, { Dispatch, SetStateAction, useEffect } from "react";

import { SearchResult } from "@/actions/search";
import { FilterOverwritesInterface } from "@/components/Brand/Articles";
import { MinMaxInterface } from "@/components/Form/Input/Range/MultiRangeInput";
import PageNavigationButton from "@/components/List/Pagination/PageNavigationButton";
import { Filter } from "@/components/pages/c/Filter/Filter";
import { Percentage } from "@/components/Percentage";
import ProductCard from "@/components/Product/ProductCard";
import ProductCardSkeleton from "@/components/Skeleton/Product/Card";
import { MedusaSorting } from "@/interfaces/Sort/SortInterface";
import { Facet } from "@/lib/contexts/search-context";
import { productCardClickEvent, ProductEventData, productListViewEvent } from "@/lib/util/gtm";
import transformProductPreview from "@/lib/util/transform-product-preview";
import ProductsLoader from "@/modules/products/products-loader";
import { ProductPreviewType } from "@/types/global";

export interface ProductListProps {
  cart?: Omit<Cart, "refundable_amount" | "refunded_total">;
  currentProductsCount: number;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  hideFilters: string[];
  infiniteData?: InfiniteData<SearchResult | undefined>;
  isLoading: boolean;
  percentSeen: number;
  previews?: ProductPreviewType[];
  processing: boolean;
  products?: {
    length?: number;
    id: number;
    data: { attributes: { metadata?: Record<string, unknown> } }[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: (filters: FilterOverwritesInterface, searchParams: ReadonlyURLSearchParams | null, offset?: number) => Promise<any>;
  setAvailableSorting: (medusaSortings: MedusaSorting[]) => void;
  setCurrentProductsCount: (length: number) => void;
  setFacets: Dispatch<SetStateAction<Facet[] | undefined>>;
  setHideFilters?: Dispatch<SetStateAction<string[]>>;
  setPercentSeen: (number: number) => void;
  setPreviews: (map: ProductPreviewType[] | undefined) => void;
  setProcessing: (b: boolean) => void;
  setTotalProductsCount: (number: number) => void;
  totalProductsCount: number;
  setPriceRange: React.Dispatch<React.SetStateAction<MinMaxInterface | undefined>>;
  gtmEventSent?: ProductEventData;
  setGtmEventSent?: React.Dispatch<React.SetStateAction<ProductEventData | undefined>>;
  gtmOmittedProductsCount?: number;
  setGtmOmittedProductsCount?: React.Dispatch<React.SetStateAction<number>>;
  listId: string;
  listName?: string;
}

const Loading = () => {
  return (
    <>
      {[...Array(6)].map((_, i) => (
        <div key={i} className="col-span-6 lg:col-span-4 m-auto mt-0 h-full w-full">
          <ProductCardSkeleton />
        </div>
      ))}
    </>
  );
};

export const ProductList = ({
  cart,
  infiniteData,
  hasNextPage,
  fetchNextPage,
  isLoading,
  hideFilters,
  processing,
  setProcessing,
  previews,
  setPreviews,
  currentProductsCount,
  setCurrentProductsCount,
  totalProductsCount,
  setTotalProductsCount,
  percentSeen,
  setPercentSeen,
  setFacets,
  setAvailableSorting,
  setPriceRange,
  products,
  gtmEventSent,
  setGtmEventSent,
  gtmOmittedProductsCount,
  setGtmOmittedProductsCount,
  listId,
  listName,
}: ProductListProps) => {
  const searchParams = useSearchParams();
  useEffect(() => {
    if (setGtmOmittedProductsCount) {
      setGtmOmittedProductsCount(0);
    }
    if (setGtmEventSent) {
      setGtmEventSent(undefined);
    }
    if (setPreviews) {
      setPreviews([]);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!cart?.region) {
      setProcessing(false);
      return;
    }

    if (infiniteData) {
      const prods = infiniteData.pages.map((page) => page?.body.products).flat();
      setPreviews(prods?.filter((prod): prod is PricedProduct => prod !== undefined).map((prod) => transformProductPreview(prod, cart.region)));
      setFacets(infiniteData?.pages?.[0]?.body.facets ?? []);
      setAvailableSorting(infiniteData?.pages?.[0]?.body.sorting ?? []);
      if (infiniteData?.pages?.[0]?.body.priceRange?.min && infiniteData?.pages?.[0]?.body.priceRange?.max) {
        setPriceRange(infiniteData?.pages?.[0]?.body.priceRange);
      }
      setProcessing(false);
    }
  }, [cart?.region, infiniteData, setAvailableSorting, setFacets, setPreviews, setProcessing]);

  useEffect(() => {
    const totalResultCount = infiniteData?.pages?.[0]?.body.pagination?.total ?? infiniteData?.pages?.[0]?.body.count ?? 0;
    if (infiniteData) {
      const prods = infiniteData?.pages.map((page) => page?.body.products).flat();
      if (!processing && prods) {
        setCurrentProductsCount(prods.length);
        setTotalProductsCount(totalResultCount);
        setPercentSeen(Math.round((prods.length / totalResultCount) * 100));
      }
    }
  }, [infiniteData, processing, setCurrentProductsCount, setTotalProductsCount, setPercentSeen]);

  useEffect(() => {
    if (infiniteData && products?.length) {
      const totalResultCount = infiniteData?.pages?.[0]?.body.pagination?.total ?? infiniteData?.pages?.[0]?.body.count ?? 0;
      setPercentSeen(Math.round((products.length / totalResultCount) * 100));
    }
  }, [currentProductsCount, totalProductsCount, setPercentSeen]);

  useEffect(() => {
    if (!previews?.length) {
      return;
    }

    const event = productListViewEvent({
      itemListId: listId,
      itemListName: listName ?? listId,
      items: previews,
      skipFirst: gtmOmittedProductsCount,
    });

    if (setGtmEventSent && event !== gtmEventSent) {
      setGtmEventSent(event);
    }
    if (setGtmOmittedProductsCount && event !== gtmEventSent) {
      setGtmOmittedProductsCount(previews.length);
    }
  }, [previews]);

  return (
    <div className={"px-5 md:px-10"}>
      <div className={"md:pl-3 md:pr-3 max-w-[1620px] mx-auto"}>
        {totalProductsCount > 0 ? (
          <>
            <Filter hide={hideFilters.length > 0 ? hideFilters : []} />
            <p className="font-bold mb-4 text-xs lg:text-base">
              {currentProductsCount} von {totalProductsCount} Artikel
            </p>
          </>
        ) : null}
        <div className="grid grid-cols-12 gap-4">
          <ProductsLoader productIds={previews?.map((p) => p.id as string) ?? []} loading={<Loading />}>
            {(product, preview, index) => {
              return (
                <div className="col-span-6 lg:col-span-4 m-auto mt-0 h-full w-full" key={`articleCard${product.id}`}>
                  <ProductCard
                    product={preview}
                    priority={index < 4}
                    isProductList={true}
                    onClick={() => {
                      productCardClickEvent({
                        itemListId: listId,
                        itemListName: listName ?? listId,
                        items: [preview],
                        index: index,
                      });
                    }}
                  />
                </div>
              );
            }}
          </ProductsLoader>
        </div>
        {(previews?.length ?? 0) > 0 && (
          <div className="mt-24 text-center mb-4">
            <p className="font-bold mb-4">{totalProductsCount > 0 ? `Sie haben ${currentProductsCount} von ${totalProductsCount} Artikel angesehen` : ""}</p>

            {totalProductsCount > 0 && <Percentage percent={percentSeen} />}

            {hasNextPage && (
              <div className="mt-16 w-full lg:w-3/6 m-auto">
                <PageNavigationButton onClick={() => fetchNextPage()} testId={"productListNextPage"} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
