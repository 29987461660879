import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useState } from "react";

/**
 * React Hook for Next Pagination
 */
export const usePagination = () => {
  const pathName = usePathname();
  const searchParams = useSearchParams();

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  const getPageByQuery = () => {
    return parseInt(searchParams.get("p") ?? "0");
  };

  const initialPage = getPageByQuery() || 1;
  const [page, setPage] = useState<number>(initialPage);

  const nextPage = () => {
    const queryString = createQueryString("p", (page + 1).toString());
    const newUrl = `${pathName}?${queryString}`;
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);

    if (page <= 2) {
      window.history.replaceState({ ...window.history.state, as: pathName, url: pathName }, "", pathName);
      return;
    }

    const queryString = createQueryString("p", page.toString());
    const newUrl = `${pathName}?${queryString}`;
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
  };

  const goToPage = (newPage?: number) => {
    if ("number" !== typeof newPage) {
      newPage = 1;
    }

    setPage(newPage);

    if (newPage < 2) {
      window.history.replaceState({ ...window.history.state, as: pathName, url: pathName }, "", pathName);
      return;
    }

    const queryString = createQueryString("p", newPage.toString());
    const newUrl = `${pathName}?${queryString}`;
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
  };

  return { page, nextPage, previousPage, goToPage };
};
