"use client";

import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

import { ProvidedProductCard } from "@/components/Product/ProductCard";
import { ScrollContainer, ScrollItem } from "@/components/Scroll/Container";
import { CommonInterface, ImagePreviewInterface, useImageSrcSet } from "@/hook/image/ImageSrcSetHook";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import { StrapiProductInterface } from "@/interfaces/Api/Product/ProductInterface";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface TopImageCarouselInterface extends StrapiComponentInterface {
  onlyDesktop?: boolean;
  productView?: React.ReactNode;
  title?: string;
  videoLink?: string;
  mediaTitle?: string;
  subTitle?: string;
  button?: string;
  buttonLink?: string;
  description?: string;
  media?: ImageInterface;
  mediaTablet?: ImageInterface;
  mediaMobile?: ImageInterface;
  textColor?: string;
  backgroundColor?: string;
  products?: {
    data: StrapiProductInterface[];
  };
  padding?: boolean | null;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "carousel.carousel"
 */
export const TOP_CAROUSEL_COMPONENT_NAME = "carousel.carousel";

export const TopImageCarousel = ({ ...component }: TopImageCarouselInterface) => {
  const onlyVideo = component?.products?.data.length === 0 && !component?.media?.data && !component?.mediaMobile?.data && !component?.mediaTablet?.data;
  const pathName = usePathname();
  const isBrandPage = pathName.includes("/b/");

  return (
    <div
      className={clsx("relative", {
        "bg-top-image": !component?.backgroundColor,
        "mx-5 mb-5": isBrandPage,
      })}
    >
      <div
        className="z-[-1] absolute top-0 bottom-[18%] md:bottom-[-50%] lg:bottom-0 w-full"
        style={{ backgroundColor: `${component?.backgroundColor ? component?.backgroundColor : null}` }}
      ></div>
      <div
        className={clsx("max-w-[1620px] mx-auto", {
          "mt-24": component.padding !== false,
          "bg-top-image": !component.backgroundColor,
          "hidden lg:block": component.onlyDesktop,
          "mb-2 md:h-[20rem] lg:h-[32rem] lg:mb-[37rem] md:mb-[30rem]": !onlyVideo,
        })}
      >
        <div className="p-5 md:p-11 pb-5">
          <div style={{ color: component?.textColor ?? "#000" }}>
            {component?.title && <div className="text-2xl lg:text-3xl font-bold m-0 uppercase">{component.title}</div>}
            {component?.subTitle && <div className="text-2xl lg:text-3xl m-0 uppercase font-light">{component.subTitle}</div>}

            {component?.description && <p className="mt-4 text-base lg:text-xl mb-5">{component.description}</p>}
          </div>
          {component?.button && component?.buttonLink && (
            <Link href={`${component.buttonLink}`} className="font-bold text-base lg:text-xl underline">
              {component.button}
            </Link>
          )}
        </div>

        <div className="grid grid-cols-12">
          {onlyVideo ? (
            <div className="col-span-12 lg:px-8 lg:pb-8 md:p-4">
              <HandleMediaType {...component} />
            </div>
          ) : (
            <>
              <div className="col-span-12 md:col-span-6 lg:pl-11 md:p-4 md:pt-0 md:pl-0">
                <HandleMediaType {...component} />
              </div>
              <div className="col-span-12 md:col-span-6 pl-3 pr-10 mt-4 md:mt-0">
                <ProductContainer products={component?.products?.data as StrapiProductInterface[]} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const HandleMediaType = ({ ...component }) => {
  const { preview, getImageConfiguration, getImage, getCaption, getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  if (!component?.videoLink) {
    const { media, mediaTablet, mediaMobile } = component;
    const defaultImage = getImage(media) as string;
    const imageTitle = component.mediaTitle ?? getCaption(media);
    const imageAlternativeText = component.mediaTitle ?? getAlternativeText(media);

    const prepareCommon: CommonInterface = {
      title: imageTitle,
      alt: imageAlternativeText,
    };

    const prepareImageConfiguration: ImagePreviewInterface = getImageConfiguration({
      desktop: defaultImage,
      tablet: getImage(mediaTablet) ?? defaultImage,
      mobile: getImage(mediaMobile) ?? defaultImage,
    });

    const { desktop, tablet, mobile } = preview(prepareCommon, prepareImageConfiguration);

    return (
      <picture>
        <source media="(min-width: 1024px)" srcSet={desktop} />
        <source media="(min-width: 768px)" srcSet={tablet} />
        <img {...mobile} style={{ objectFit: "cover" }} className={"w-full aspect-[4/3]"} title={prepareCommon.title} alt={prepareCommon.alt} />
      </picture>
    );
  }

  return (
    <iframe
      width="100%"
      src={component.videoLink}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
      className="aspect-video w-full"
    ></iframe>
  );
};

const ProductContainer = ({ products }: { products: StrapiProductInterface[] | null }) => {
  return (
    <ScrollContainer yOverflow={false} className="h-[10rem] lg:h-[30rem]">
      {products?.map((product: StrapiProductInterface) => {
        return (
          <ScrollItem key={`scroll-product-top${product.attributes.handle}`}>
            <ProvidedProductCard product={product} className="w-[10rem] lg:w-[27rem] p-1" />
          </ScrollItem>
        );
      })}
    </ScrollContainer>
  );
};
