export const Badge = ({ name, isSelected, className, onClick }: { name: string; isSelected?: boolean; className?: string; onClick?: () => void }) => {
  return (
    <p
      onClick={onClick}
      className={`${
        isSelected ? "bg-pink-700 text-white" : "category-bg-gray text-black"
      } block text-center font-bold rounded-xl p-1 text-xs xl:text-[17px] whitespace-nowrap pl-2 pr-2 ${className ?? ""}`}
    >
      {name}
    </p>
  );
};
