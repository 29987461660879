import React from "react";

import { SlideInterface } from "@/components/pages/app/Slider/BrandSliderBlock";
import { SubBrand } from "@/components/pages/app/Slider/SubBrandSlider";
import { ScrollContainer, ScrollItem } from "@/components/Scroll/Container";
import { BrandInterface } from "@/hook/content";

export const BrandScrollContainer = ({
  subbrands,
  id,
  isBrandPage,
}: {
  subbrands: BrandInterface[] | SlideInterface[];
  id?: string;
  isBrandPage?: boolean;
}) => {
  return (
    <ScrollContainer yOverflow={false} id={id} className="h-[10rem] lg:h-[32.5rem]">
      {subbrands?.map((subbrand: BrandInterface | SlideInterface) => {
        if ("displayname" in subbrand) {
          return (
            <ScrollItem key={`scroll-sub-brand-${subbrand.displayname}`}>
              <SubBrand
                key={`sub-brand-${subbrand.displayname}`}
                subbrand={subbrand}
                isBrandPage={isBrandPage}
                className="w-[10rem] lg:w-[27.5rem] md:pr-4 pr-7"
              />
            </ScrollItem>
          );
        }
        return null;
      })}
    </ScrollContainer>
  );
};
