"use client";

import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { useCart } from "medusa-react";
import Link from "next/link";
import React, { Suspense, useCallback, useEffect, useState } from "react";

import { page } from "@/actions/campaigns";
import { SearchParams } from "@/actions/search";
import { ProductSlider } from "@/components/pages/p/ProductSlider";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import transformProductPreview from "@/lib/util/transform-product-preview";
import { ProductPreviewType } from "@/types/global";

export interface BlockSliderInterface extends StrapiComponentInterface {
  backgroundColor?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  buttonLink?: string;
  buttonText?: string;
  textColor?: string;
  products?: {
    data: ProductPreviewType[];
  };
  ffCampaignPageId?: string;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "slider.productSlider"
 */
export const BLOCK_SLIDER_COMPONENT_NAME = "slider.block";

export const BlockSlider = ({ ...component }: BlockSliderInterface) => {
  const [previews, setPreviews] = useState<undefined | ProductPreviewType[]>();
  const { cart } = useCart();

  const query = useCallback(
    async (pageId: string, limit?: number) => {
      const requestLimit = limit ?? 10;

      if (typeof cart?.sales_channel_id !== "string") {
        return;
      }

      const params: SearchParams = {
        type: "search",
        additionalOptions: {
          expand: "variants,variants.options",
          pageId,
        },
      };

      return page({ pageId, sales_channel_id: cart.sales_channel_id, limit: requestLimit, ...params });
    },
    [cart?.sales_channel_id],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!component.ffCampaignPageId || !cart) return;

      try {
        const res = await query(component.ffCampaignPageId, 10);
        if (res?.body) {
          setPreviews(res.body.products.map((prod: PricedProduct) => transformProductPreview(prod, cart.region)));
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [component.ffCampaignPageId, cart, query]);

  return (
    <div className="mb-10 pt-5 md:pt-10">
      <div className="px-5 md:px-10" style={{ backgroundColor: component?.backgroundColor }}>
        <div className="md:pl-3 md:pr-3 max-w-[1620px] mx-auto">
          <div style={{ color: component?.textColor ?? "#000" }}>
            {component?.title && <div className="text-2xl lg:text-3xl uppercase font-bold">{component.title}</div>}
            {component?.subTitle && <div className="text-2xl lg:text-3xl uppercase font-light">{component.subTitle}</div>}
            {component?.description && <p className="mt-2 mb-5 text-base lg:text-xl">{component.description}</p>}
          </div>
          {component?.buttonText && component?.buttonLink && (
            <div className="mt-5 mb-5">
              <Link href={`${component.buttonLink}`} className="font-bold underline text-base lg:text-xl">
                {component.buttonText}
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="pl-5 md:pl-10 max-w-[1620px] mx-auto">
        {component.ffCampaignPageId && previews && <ProductSlider id={`cmsSlider${component.id}`} products={previews} />}
        {!component.ffCampaignPageId && component?.products && component.products.data.length >= 1 && (
          <ProductSlider id={`cmsSlider${component.id}`} products={component?.products?.data} fromStrapi={true} />
        )}
      </div>
    </div>
  );
};

const SuspendedBlockSlider = (props: BlockSliderInterface) => (
  <Suspense fallback={<div>Loading...</div>}>
    <BlockSlider {...props} />
  </Suspense>
);

export default SuspendedBlockSlider;
