"use client";

import clsx from "clsx";
import Image from "next/image";
import React from "react";

import { CommonInterface, ImagePreviewInterface, useImageSrcSet } from "@/hook/image/ImageSrcSetHook";

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "brand.header"
 */
export const BRAND_HEADER_COMPONENT_NAME = "brand.header";

export const BrandHeader = ({ ...component }) => {
  const { getAlternativeText, getCaption, getImage, getImageConfiguration, preview } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  const { image, imageTablet, imageMobile, imageTitle, logo, logoTitle } = component;
  const defaultImage = getImage(image) as string;
  const imageCaption = imageTitle ?? getCaption(image);
  const imageAlternativeText = imageTitle ?? getAlternativeText(image);
  const logoCaption = logoTitle ?? getCaption(logo);
  const logoAlternativeText = logoTitle ?? getAlternativeText(logo);

  const prepareCommon: CommonInterface = {
    title: imageCaption,
    alt: imageAlternativeText,
  };

  const prepareImageConfiguration: ImagePreviewInterface = getImageConfiguration({
    desktop: defaultImage,
    tablet: getImage(imageTablet) ?? defaultImage,
    mobile: getImage(imageMobile) ?? defaultImage,
  });

  const { desktop, tablet, mobile } = preview(prepareCommon, prepareImageConfiguration);

  return (
    <div className={clsx("max-w-[1620px] mx-auto", { "px-5 pb-5": component.padding !== false })}>
      <div style={{ color: component?.textColor ?? "#000" }}>
        <h1 className="text-2xl lg:text-3xl uppercase font-bold">{`${component?.parent ? component?.parent : component.title}`}</h1>
        {component?.parent && <h2 className="text-2xl lg:text-3xl uppercase font-light">{component.title}</h2>}
        {component?.subTitle && <div className="text-2xl lg:text-3xl uppercase font-light mb-3">{component.subTitle}</div>}
      </div>

      <div className="relative flex">
        <div style={{ backgroundColor: component?.logoBackground ?? "none" }} className="bg-gray-300 lg:block hidden basis-[40%]">
          <div className="relative block w-full h-full">
            {component?.logo?.data?.attributes?.url && (
              <div className="absolute m-auto left-0 right-0 mx-12 lg:mx-20 top-1/2 transform -translate-y-1/2">
                <Image
                  title={logoCaption}
                  className="p-10 bg-white md:p-5 h-[auto] w-[50rem] lg:w-[20rem] mx-auto"
                  style={{ objectFit: "cover" }}
                  alt={logoAlternativeText}
                  width={1250}
                  height={0}
                  loader={(loader) => process.env.STRAPI_URL + loader?.src}
                  src={component.logo.data.attributes.url as string}
                />
              </div>
            )}
          </div>
        </div>

        <picture className="lg:basis-[60%] w-full">
          <source media="(min-width: 1024px)" srcSet={desktop} />
          <source media="(min-width: 768px)" srcSet={tablet} />
          <img {...mobile} style={{ objectFit: "cover" }} className={"w-full h-auto"} alt={prepareCommon.alt} />
        </picture>

        {component?.logo?.data?.attributes?.url && (
          <div className="bg-white h-[5rem] w-[6.5rem] md:w-[13rem] md:h-[10rem] lg:hidden block absolute bottom-0 mb-3 lg:mb-10 lg:top-0 lg:my-auto lg:ml-10 ml-3">
            <Image
              className="p-2 md:p-5 h-[5rem] md:h-[10rem] lg:h-[15rem]"
              style={{ objectFit: "cover" }}
              title={logoCaption}
              alt={logoAlternativeText}
              width={1250}
              height={0}
              loader={(loader) => process.env.STRAPI_URL + loader?.src}
              src={component.logo.data.attributes.url as string}
            />
          </div>
        )}
      </div>
    </div>
  );
};
