import clsx from "clsx";
import React, { useState } from "react";

import { Collapse } from "@/components/Form/Button/Collapse";
import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";
import { CheckLineIcon } from "@/components/Icon/Check/CheckLineIcon";
import ReadMore from "@/components/StaticPage/ReadMore";
import { useSearch } from "@/lib/contexts/search-context";

export const TEXT_FILTER_NAME = "TEXT_FILTER";

interface TextValue {
  title: string;
  recordCount: number;
  selected: boolean;
}

interface TextFilterInterface {
  attribute: string;
  title: string;
  selectedValues: string[];
  availableValues: TextValue[];
}

const RenderArrow = ({ isCollapsed }: { isCollapsed: boolean }) => {
  return isCollapsed ? <ArrowDropUpIcon width={20} className="float-right mt-1" /> : <ArrowDropDownIcon width={20} className="float-right mt-1" />;
};

export const TextFilter = ({ attribute, title, availableValues }: TextFilterInterface) => {
  const [isCollapsed, setCollapsed] = useState<boolean>(false);
  const { selectedFacets, setSelectedFacets } = useSearch();
  const _selectedFacts = [...(selectedFacets ?? [])];
  const findAttribute = _selectedFacts?.find((facets) => facets.name === attribute);
  const selectedValueCount = findAttribute?.values.filter((val) => val.selected).length ?? 0;

  const onClick = (value: TextValue) => {
    if (handleDeleteAction(value)) {
      return;
    }

    if (!findAttribute) {
      _selectedFacts.push({ name: attribute, values: [{ ...value, selected: true }] });
      setSelectedFacets(_selectedFacts);
    } else {
      const index = _selectedFacts.indexOf(findAttribute);

      findAttribute.values.push({ ...value, selected: true });
      _selectedFacts[index] = findAttribute;
    }

    setSelectedFacets(_selectedFacts);
  };

  const handleDeleteAction = (value: TextValue) => {
    const findAttributeByValue = _selectedFacts.find((facets) => {
      if (facets.name !== attribute) {
        return null;
      }

      const findValue = facets.values.find((_value: TextValue) => _value.title === value.title);
      return findValue ? facets : null;
    });

    if (findAttributeByValue) {
      const index = _selectedFacts.indexOf(findAttributeByValue);
      const findValue = findAttributeByValue.values.find((_value: TextValue) => _value.title === value.title);
      const valueIndex = findAttributeByValue.values.indexOf(findValue ?? value);

      findAttributeByValue.values.splice(valueIndex, 1);

      if (findAttributeByValue.values.length <= 0) {
        _selectedFacts.splice(index, 1);
      } else {
        _selectedFacts[index] = findAttributeByValue;
      }

      setSelectedFacets(_selectedFacts);
      return true;
    }

    return false;
  };

  const isSelectedValue = (val: TextValue) => {
    const test = selectedFacets?.find((facets) => facets.name === attribute)?.values.find((value) => value.title === val.title);
    return !!test;
  };

  const renderValues = () => {
    const vals = availableValues.map((val: TextValue) => {
      const isSelected = isSelectedValue(val);

      return (
        <div className="block m-auto my-3 cursor-pointer" onClick={() => onClick(val)} key={val.title}>
          <p
            className={clsx("", {
              "font-bold selected": isSelected,
              "font-normal": !isSelected,
            })}
          >
            {val.title} ({val.recordCount ?? 0}){isSelected && <CheckLineIcon className={"float-right mt-1 mr-2"} width={20} />}
          </p>
        </div>
      );
    });

    return <ReadMore className={""} hideAtBottom={false} hideAtTop={true} elementsShown={5} contentEls={vals} toggleInactiveClass={"block mb-3"} />;
  };

  return (
    <Collapse
      title={
        <>
          <span className={`tracking-wide font-semibold text-md`}>
            <span className="mr-1">{title}</span>
            {selectedValueCount >= 1 ? `(${selectedValueCount})` : <></>}
          </span>
          <RenderArrow isCollapsed={isCollapsed} />
        </>
      }
      customStyle={"category-bg-gray -mx-4 mb-1 !w-auto pr-6 pl-11"}
      customContentStyle={"bg-white"}
      onClick={() => setCollapsed(!isCollapsed)}
      enabled={isCollapsed}
      id={`${attribute}`}
    >
      <div className="pl-14">{renderValues()}</div>
    </Collapse>
  );
};
