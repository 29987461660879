import React from "react";

interface InputInterface extends Omit<React.HTMLProps<HTMLInputElement>, "placeholder"> {
  type: string;
  required?: boolean;
  label: string;
  name: string;
  errorMessage?: string;
  className?: string;
}

export const Input: React.ForwardRefExoticComponent<React.PropsWithoutRef<InputInterface> & React.RefAttributes<HTMLInputElement>> = React.forwardRef(
  ({ type, required, label, name, errorMessage, className, ...props }: InputInterface, ref) => {
    return (
      <>
        <input
          className={`${className} border-2 focus:outline-none p-2 font-semibold ${
            !errorMessage ? "border-gray-500 bg-gray-100 text-gray-700" : "border-red-500 bg-gray-100 text-red-500 placeholder-red-500"
          }`}
          type={type}
          name={name}
          required={required}
          placeholder={!required ? label : `${label}*`}
          ref={ref}
          {...props}
        />
        {errorMessage ? <p className="text-red-500 font-semibold">{errorMessage}</p> : ""}
      </>
    );
  },
);

Input.displayName = "Input";
