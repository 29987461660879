"use client";

import clsx from "clsx";
import React from "react";

import Button from "@/components/Form/Button/Button";
import { CommonInterface, ImagePreviewInterface, useImageSrcSet } from "@/hook/image/ImageSrcSetHook";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface ActionBannerInterface extends StrapiComponentInterface {
  title?: string;
  subTitle?: string;
  text?: string;
  button?: string;
  buttonLink?: string;
  image?: ImageInterface;
  imageTablet?: ImageInterface;
  imageMobile?: ImageInterface;
  textColor?: string;
  backgroundColor?: string;
  imageTitle?: string;
  padding?: boolean | null;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "banner.action-banner"
 */
export const ACTION_BANNER_COMPONENT_NAME = "banner.action-banner";

export const ActionBanner = ({ ...component }: ActionBannerInterface) => {
  const { preview, getImageConfiguration, getImage, getCaption, getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  if (!component) {
    return <></>;
  }

  const { image, imageTablet, imageMobile, imageTitle } = component;
  const defaultImage = getImage(image) as string;
  const title = imageTitle ?? getCaption(image);
  const imageAlternativeText = imageTitle ?? getAlternativeText(image);

  const prepareCommon: CommonInterface = {
    title: title,
    alt: imageAlternativeText,
  };

  const prepareImageConfiguration: ImagePreviewInterface = getImageConfiguration({
    desktop: defaultImage,
    tablet: getImage(imageTablet) ?? defaultImage,
    mobile: getImage(imageMobile) ?? defaultImage,
  });

  const { desktop, tablet, mobile } = preview(prepareCommon, prepareImageConfiguration);

  return (
    <div
      className={clsx({ "bg-gray-100": !component.backgroundColor })}
      style={component.backgroundColor ? { backgroundColor: component.backgroundColor } : {}}
    >
      <div className={clsx("grid grid-cols-12 max-w-[1620px] mx-auto", { "mt-10 mb-24": component.padding !== false })}>
        <div className="col-span-12 md:col-span-6 block md:hidden">
          <picture>
            <source media="(min-width: 1024px)" srcSet={desktop} />
            <source media="(min-width: 768px)" srcSet={tablet} />
            <img
              {...mobile}
              style={{ width: "100%", objectFit: "cover" }}
              className={"h-[25rem] lg:h-[30rem]"}
              alt={component.image?.data?.attributes.name ?? component.imageTitle ?? "Aktionsbanner"}
            />
          </picture>
        </div>
        <div className="col-span-12 md:col-span-6 p-5 md:p-16 flex md:items-center lg:items-end md:h-[25rem] lg:h-[30rem]">
          <div className="lg:pr-30">
            <div className="w-full" style={{ color: component.textColor ?? "#000" }}>
              {component.title && <div className="font-bold text-2xl lg:text-3xl uppercase">{component.title}</div>}
              {component.subTitle && <div className="text-2xl lg:text-3xl uppercase">{component.subTitle}</div>}

              {component.text && <p className="mt-4 text-base lg:text-xl lg:w-3/4 w-full">{component.text}</p>}
            </div>

            {component.button && component.buttonLink && (
              <Button title={component.button} outline href={component.buttonLink} className="p-7 uppercase text-center mt-7 text-base lg:text-xl" />
            )}
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 md:block hidden">
          <picture>
            <source media="(min-width: 1024px)" srcSet={desktop} />
            <source media="(min-width: 768px)" srcSet={tablet} />
            <img {...mobile} style={{ width: "100%", objectFit: "cover" }} className={"h-[25rem] lg:h-[30rem]"} alt={prepareCommon.alt} />
          </picture>
        </div>
      </div>
    </div>
  );
};
