import { useCart } from "medusa-react";
import { useSearchParams } from "next/navigation";
import { useState } from "react";

import { usePagination } from "@/hook/pagination";
import { useSearch } from "@/lib/contexts/search-context";
import { ProductEventData } from "@/lib/util/gtm";
import { ProductPreviewType } from "@/types/global";

export const useSharedStates = () => {
  const [previews, setPreviews] = useState<undefined | ProductPreviewType[]>();
  const [currentProductsCount, setCurrentProductsCount] = useState<number>(0);
  const [totalProductsCount, setTotalProductsCount] = useState<number>(0);
  const [percentSeen, setPercentSeen] = useState<number>(100);
  const [processing, setProcessing] = useState<boolean>(false);
  const [hideFilters, setHideFilters] = useState<string[]>([]);
  const { cart } = useCart();
  const { setFacets, setAvailableSorting, setPriceRange } = useSearch();
  const searchParams = useSearchParams();
  const [gtmEventSent, setGtmEventSent] = useState<ProductEventData | undefined>(undefined);
  const [gtmOmittedProductsCount, setGtmOmittedProductsCount] = useState<number>(0);
  const pagination = usePagination();

  return {
    previews,
    setPreviews,
    currentProductsCount,
    setCurrentProductsCount,
    totalProductsCount,
    setTotalProductsCount,
    percentSeen,
    setPercentSeen,
    processing,
    setProcessing,
    hideFilters,
    setHideFilters,
    cart,
    setFacets,
    setAvailableSorting,
    setPriceRange,
    searchParams,
    gtmEventSent,
    setGtmEventSent,
    gtmOmittedProductsCount,
    setGtmOmittedProductsCount,
    pagination,
  };
};
