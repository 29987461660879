import Link from "next/link";
import React from "react";

import { Badge } from "@/components/Badge";
import { InformationNavigationItemInterface } from "@/interfaces/pages/Information/InformationPageInterface";

export const NavigationBadges = ({
  navigation,
  setSelectedNavigation,
  selectedNavigation,
  uppercase,
}: {
  navigation: InformationNavigationItemInterface[];
  setSelectedNavigation: React.Dispatch<React.SetStateAction<InformationNavigationItemInterface | null>>;
  selectedNavigation: InformationNavigationItemInterface | null;
  uppercase?: boolean;
}) => {
  return (
    <div className="overflow-x-auto flex mb-2 pb-3" style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }}>
      {navigation.map((navigation: InformationNavigationItemInterface, index: number) => (
        <Link href={`#${navigation.name}`} key={index}>
          <div className="pr-5">
            <div onClick={() => setSelectedNavigation(navigation)}>
              <Badge name={navigation.title} isSelected={navigation === selectedNavigation} className={`${uppercase && "uppercase"}`} />
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
