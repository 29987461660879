"use client";

import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";

import { Carousel, CarouselItem } from "@/components/Carousel";
import { imageUrlHelper } from "@/helper/ImageUrlHelper";
import { useImageSrcSet } from "@/hook/image/ImageSrcSetHook";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface SlideInterfaceItem {
  id: number;
  image: ImageInterface;
  logo: ImageInterface;
  imageTitle: string;
  logoTitle: string;
  logoUrl?: string;
  imageUrl?: string;
}

interface ImageSliderProps {
  slides: SlideInterfaceItem[];
  noLogo?: boolean;
  className?: string;
}

const Slides = ({ slides, noLogo, ...props }: { slides: SlideInterfaceItem[]; index: number; noLogo?: boolean }) => {
  const totalChildren = slides.length - 1;

  const index = props.index;
  let nextIndex = index + 1;
  let next2Index = nextIndex + 1;

  if (next2Index > totalChildren) {
    next2Index = 0;
  }

  if (nextIndex > totalChildren) {
    nextIndex = nextIndex - totalChildren;
  }

  if (index === totalChildren) {
    nextIndex = 0;
    next2Index = 1;
  }

  return (
    <>
      <SlideItem className={"block"} slide={slides[index]} noLogo={noLogo} />

      {slides.length > 1 && <SlideItem className={"hidden sm:hidden md:block"} slide={slides[nextIndex]} noLogo={noLogo} />}
      {slides.length > 2 && <SlideItem className={"hidden md:hidden lg:block"} slide={slides[next2Index]} noLogo={noLogo} />}
    </>
  );
};

const SlideItem = ({ className, slide, noLogo }: { slide: SlideInterfaceItem; className: string; noLogo?: boolean }) => {
  const { getImage, getCaption, getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  const { image: rawImage, logo: rawLogo, imageTitle, logoTitle: rawLogoTitle } = slide;
  const defaultImage = getImage(rawImage) as string;
  const defaultLogo = getImage(rawLogo) as string;
  const title = imageTitle ?? getCaption(rawImage);
  const imageAlternativeText = imageTitle ?? getAlternativeText(rawImage);
  const logoTitle = rawLogoTitle ?? getCaption(rawLogo);
  const logoAlternativeText = imageTitle ?? getAlternativeText(rawLogo);

  const logo = (
    <Image
      className="w-[6rem] max-h-[5rem] lg:w-[10rem] lg:max-h-[8rem] bg-white absolute bottom-0 ml-3 mb-3 image-center-size-25 aspect-[4/3]"
      alt={logoAlternativeText}
      title={logoTitle}
      width={100}
      height={100}
      loader={(loader) => process.env.STRAPI_URL + loader?.src}
      src={defaultLogo}
    />
  );

  const image = (
    <Image
      className="w-full max-h-auto md:max-h-[17rem] lg:max-h-[30rem] aspect-[4/3]"
      style={{ objectFit: "cover" }}
      alt={imageAlternativeText}
      title={title}
      width={1250}
      height={0}
      loader={(loader) => imageUrlHelper(new URL(process.env.STRAPI_URL + loader?.src).toString())}
      src={defaultImage}
    />
  );

  return (
    <div className={`col-span-12 md:col-span-6 lg:col-span-4 ${className}`}>
      <div className={`relative`}>
        {slide.imageUrl ? (
          <>
            {!noLogo && <Link href={slide.logoUrl ?? slide.imageUrl}>{logo}</Link>}
            <Link href={slide.imageUrl}>{image}</Link>
          </>
        ) : (
          <>
            {!noLogo && (slide.logoUrl ? <Link href={slide.logoUrl}>{logo}</Link> : logo)}
            {image}
          </>
        )}
      </div>
    </div>
  );
};

export const BrandSlider = ({ slides, noLogo, className }: ImageSliderProps) => {
  const [sliderIndex, setSliderIndex] = useState<number>(0);

  return (
    <Carousel totalChildren={slides.length} setSliderIndex={setSliderIndex} className={`md:max-h-[17rem] lg:max-h-[30rem] ${className ?? ""}`}>
      {slides.map((slide: SlideInterfaceItem & { index?: number }, index: number) => {
        return (
          <CarouselItem noFade key={`brandSlider-${slide.id}`}>
            <div className={`grid w-full grid-cols-12 gap-4 ${sliderIndex === index ? "block" : "hidden"}`}>
              <Slides index={index} slides={slides} noLogo={noLogo} />
            </div>
          </CarouselItem>
        );
      })}
    </Carousel>
  );
};
