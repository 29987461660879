"use client";

import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { useImageSrcSet } from "@/hook/image/ImageSrcSetHook";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface TeaserMatrixInterface extends StrapiComponentInterface {
  Matrix?: TeaserMatrixItemInterface[];
  textColor?: string;
  title?: string;
}

export interface TeaserMatrixItemInterface {
  id: number;
  background?: string;
  backgroundColor?: string;
  image?: ImageInterface;
  image2to1?: ImageInterface;
  imageTitle?: string;
  isShort?: boolean;
  link?: string;
  subTitle?: string;
  textColor: string;
  title: string;
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "teaser.matrix"
 */
export const TEASER_MATRIX_COMPONENT_NAME = "teaser.matrix";

export const MatrixCard = ({
  title,
  subTitle,
  backgroundColor,
  background,
  textColor,
  className,
  image,
  imageTitle,
  link,
  isShort,
}: TeaserMatrixItemInterface & { className?: string }) => {
  const { getAlternativeText } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const imageAlternativeText = imageTitle ?? getAlternativeText(image);

  return (
    <>
      <div
        className={clsx(className, `relative lg:max-h-[20rem] m-2 md:m-3`)}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <Link
          href={link ?? "#!"}
          className={clsx("text-white absolute", {
            "text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full": subTitle,
            "p-4 bottom-0 left-0": !subTitle,
          })}
          style={{ color: textColor }}
        >
          <h3
            className={clsx("text-lg md:text-2xl uppercase font-bold", {
              "break-word": isShort,
            })}
          >
            {title}
            {!subTitle ? (
              <ArrowDropRightIcon fill={`${textColor ? textColor : "#fff"}`} className={`w-[1.5rem] md:w-[2rem] inline-block align-bottom`} />
            ) : null}
          </h3>
          {subTitle ? <div className="text-xs md:text-xl mt-2 underline font-light">{subTitle}</div> : null}
        </Link>

        {typeof background === "string" && (
          <Link href={link ?? "#!"}>
            <Image
              className="flex flex-wrap justify-center items-center w-full h-full image-center object-cover"
              loader={(loader) => loader.src}
              src={background}
              title={imageTitle}
              alt={imageAlternativeText}
              width={100}
              height={100}
            />
          </Link>
        )}
      </div>
    </>
  );
};

export const TeaserMatrix = ({ ...component }: TeaserMatrixInterface) => {
  const items = component?.Matrix || [];
  const { getImage, getCaption } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });

  return (
    <div className="md:p-7 max-w-[1620px] mx-auto">
      <h2 className="uppercase text-2xl md:text-3xl ml-3 mb-2 md:mb-5" style={{ color: component?.textColor ?? "#000" }}>
        {component?.title}
      </h2>

      <div className="grid grid-cols-2 lg:grid-cols-5">
        {items.map((item: TeaserMatrixItemInterface, index) => {
          const isShort = items.length === 10 || index + 1 < items.length; // kurze Kachel wenn Anzahl 10 oder nicht letzte Kachel
          const image = getImage(item.image);
          const image2to1 = getImage(item.image2to1);
          const imageUrl = image ? (process.env.STRAPI_URL ?? "") + image : undefined;
          const image2to1Url = image2to1 ? (process.env.STRAPI_URL ?? "") + image2to1 : imageUrl;
          const alternativeCaption = isShort ? getCaption(item.image) : getCaption(item.image2to1);
          const imageCaption = item.imageTitle ?? alternativeCaption;
          const background = isShort ? imageUrl : image2to1Url;

          return (
            <MatrixCard
              id={item.id}
              key={`matrixItemCard${item.id}`}
              className={`${isShort ? "lg:max-w-[20rem] aspect-square" : "lg:max-w-[46rem] col-span-2 aspect-[38/18] 2xl:aspect-[27/13]"} relative lg:max-h-[20rem] m-2 md:m-3`}
              title={item.title}
              subTitle={item.subTitle}
              isShort={isShort}
              image={item.image}
              image2to1={item.image2to1}
              imageTitle={imageCaption}
              backgroundColor={item.backgroundColor}
              textColor={item.textColor ?? component.textColor ?? "#000"}
              background={background}
              link={item.link}
            />
          );
        })}
      </div>
    </div>
  );
};
